export enum GameType {
    NoGame = 'NoGame',
    BoatGame = 'BoatGame',
    PlatformerGame = 'PlatformerGame',
    SpaceGame = 'SpaceGame',
    DragAndDropGame = 'DragAndDropGame',
    HunterGameTargetMode = 'HunterGameTargetMode',
    HunterGameTimeMode = 'HunterGameTimeMode',
    HunterGameTimeTargetMode = 'HunterGameTimeTargetMode',
    SoupGame = 'SoupGame'
}