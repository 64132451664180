import { Sprite } from "@pixi/sprite";
import { ObservablePoint } from "@pixi/core";
import { FlameComponent } from "../component/FlameComponent";
import { Container, DisplayObject } from "@pixi/display";
import { Entity } from "../../../common/entities/Entity";

export class PlayerEntity extends Entity {
    private _sprite: Sprite;
    private _health: number;
    private _leftFlame: FlameComponent
    private _middleFlame: FlameComponent
    private _rightFlame: FlameComponent

    constructor(
        sprite: Sprite,
        health: number
    ) {
        super()

        this._sprite = sprite;
        this._health = health;
        this._leftFlame = new FlameComponent({ x: -95, y: 85 })
        this._middleFlame = new FlameComponent({ x: 2, y: 20 })
        this._rightFlame = new FlameComponent({ x: 95, y: 85 })
        
        this._leftFlame.emitter.emit = true;
        this._leftFlame.container.scale.x = 1.25;
        this._middleFlame.emitter.emit = true;
        this._middleFlame.container.scale.x = 2.5;
        this._middleFlame.container.scale.y = 1.75;
        this._rightFlame.emitter.emit = true;
        this._rightFlame.container.scale.x = 1.25;
    }

    public get health(): number {
        return this._health;
    }

    public get anchor(): ObservablePoint {
        return this._sprite.anchor;
    }

    public set anchor(anchor: ObservablePoint) {
        this._sprite.anchor = anchor;
    }

    public display(container: Container<DisplayObject>): void {
        this._container.addChild(this._leftFlame.container)
        this._container.addChild(this._middleFlame.container)
        this._container.addChild(this._rightFlame.container)
        this._container.addChild(this._sprite)
        container.addChild(this._container);
    }

    public update(delta: number): void {
        this._leftFlame.emitter.update(delta * 0.01);
        this._middleFlame.emitter.update(delta * 0.01);
        this._rightFlame.emitter.update(delta * 0.01);
    }
}