import { Button, Navbar as MTNavbar } from "@material-tailwind/react";
import { Link, useNavigate } from "react-router-dom";

const UnauthenticatedActions: React.FC = () => {
  const navigate = useNavigate();
  return (
    <div className="flex gap-2">
      <Button
        variant="text"
        color="deep-purple"
        onClick={() => navigate("/sign-in")}
      >
        Connexion
      </Button>
      <Button color="deep-purple" onClick={() => navigate("/sign-up")}>
        Inscription
      </Button>
    </div>
  );
};


const Navbar: React.FC = () => {
  return (
    <MTNavbar className="sticky flex justify-between top-0 h-max max-w-full rounded-none px-4 py-2 lg:px-6 lg:py-4 z-20">
      <Link to="/">
        <div className="flex gap-1 items-center">
          <img className="mx-2 h-12" src="/assets/millo-logo.png" alt="logo" />
          <h1 className="text-2xl text-black">
            <span className="font-black">Millo</span> Ergothérapie
          </h1>
        </div>
      </Link>
      <UnauthenticatedActions />
    </MTNavbar>
  );
};

export default Navbar;
