import { authenticatedClient, updateAuthHeader } from "../client";
import { FindPatientGameLevelSettingsRequest as FindPlayerGameLevelSettingsRequest } from "./dto/find-patient-game-level-settings.request";
import { GameLevelSettingsStructureResponse } from "./dto/game-level-settings-structure.response";
import { GameLevelSettingsResponse } from "./dto/game-level-settings.response";

export class GameLevelSettingsApi {
    public static async getSettingsTypeStructure(type: string) {
        updateAuthHeader();
        return authenticatedClient.get<GameLevelSettingsStructureResponse>(
            `/game-levels/settings/types/${type}/settings-structure`);
    }

    public static async getPatientSettings(request: FindPlayerGameLevelSettingsRequest) {
        updateAuthHeader();
        return authenticatedClient.get<GameLevelSettingsResponse>(
            `/game-levels/settings/${request.type}/player/${request.playerId}`);
    }    
}