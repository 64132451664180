import { PlayerEntity } from "../entities/PlayerEntity";
import { SpriteCache } from "./SpriteCache";

export interface PlayerParameters {
    health: number
}

export class PlayerFactory {
    constructor(
        private readonly screen: { width: number, height: number }
    ) { }

    private generateEnityParameters(parameters: PlayerParameters): PlayerEntity {
        const player = new PlayerEntity(
            SpriteCache.player,
            parameters.health
        )

        player.container.scale.x = 0.15;
        player.container.scale.y = 0.15;
        player.anchor.set(0.5, 0.5);
        player.container.x = (this.screen.width / 2);
        player.container.y = this.screen.height - (this.screen.height * 0.15);
        player.container.pivot.set(
            player.container.width / 2,
            player.container.height / 2
        );
        return player;
    }

    public generate(parameters: PlayerParameters): PlayerEntity {
        return this.generateEnityParameters(
            parameters
        )
    }
}