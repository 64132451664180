import Form from "@rjsf/core";
import validator from "@rjsf/validator-ajv8";
import { JSONSchema7 } from "json-schema";
import { useEffect, useState } from "react";
import { GameSettingsHandler } from "../../domain/game/GameSettingsHandler";
import ArrayFieldTemplate from "./templates/ArrayFieldTemplate";
import FieldTemplate from "./templates/FieldTemplate";
import ObjectFieldTemplate from "./templates/ObjectFieldTemplate";
import TextWidget from "./widgets/TextWidget";
import CheckboxWidget from "./widgets/CheckBoxWidget";
import SubmitButtonTemplate from "./templates/SubmitButtonTemplate";
import SelectWidget from "./widgets/SelectWidget";

const gameSettingsHandler = new GameSettingsHandler();

const GameSettingsForm = ({
  settingsValue,
  settingsStructure,
  onUpdate,
  onSubmit,
}: {
  settingsValue: any;
  settingsStructure: any;
  onUpdate: (value: any) => void;
  onSubmit: (value: { [key: string]: any }) => void;
}) => {

  const [schema, setSchema] = useState<JSONSchema7>({});
  const [formData, setFormData] = useState<{ [key: string]: any }>({});

  useEffect(() => {
    setFormData(settingsValue);
  }, [settingsValue]);

  useEffect(() => {
    const result = gameSettingsHandler.sanitizeInput(settingsStructure);
    delete result.properties?.type

    setSchema(result);
  }, [settingsStructure]);


  return (
    <div className="px-5 py-16 min-h-full">
      <h2 className="cartoon text-5xl font-bold mb-10 text-center text-white">Paramètres du jeu</h2>
      <Form
        schema={schema}
        formData={formData}
        validator={validator}
        onChange={(e) => {
          setFormData(e.formData);
          onUpdate(e.formData);
        }}
        onSubmit={() => onSubmit(formData)}
        onError={(e) => {
          console.error(e);
        }}
        templates={{
          ArrayFieldTemplate,
          FieldTemplate,
          ObjectFieldTemplate,
          ButtonTemplates: {
            SubmitButton: SubmitButtonTemplate
          }
        }}
        widgets={{
          TextWidget,
          CheckboxWidget,
          SelectWidget
        }}
      />
    </div>
  );
};

export default GameSettingsForm;
