import { FaPlus } from "react-icons/fa";
import { Link } from "react-router-dom";
import { PatientFoundResponse } from "../../api/patient/dto/patient-found.response";

const CardWrapper = (props: { children: React.ReactNode }) => {
  return (
    <div className="flex flex-col border bg-deep-purple-50 items-center justify-center h-48 w-48 md:h-60 md:w-60 gap-3 rounded-3xl hover:transform hover:scale-105 transition-transform">
      {props.children}
    </div>
  );
};

export const PatientCard = (
  props: Readonly<{ patient: PatientFoundResponse; index: number }>
) => {

  return (
    <Link to={`/patient/${props.patient.id}`}>
      <CardWrapper>
        <div className="relative">
          <img
            src="/assets/illustrations/avatar/1.png"
            alt={`${props.patient.firstname} ${props.patient.lastname}`}
            className="h-28 w-28 md:h-36 md:w-36"
          />
        </div>
        <p className="font-semibold text-center">
          {props.patient.firstname} {props.patient.lastname}
        </p>
      </CardWrapper>
    </Link>
  );
};

export const CreatePatientCard = (props: { onClick?: () => void }) => {
  return (
    <button onClick={props.onClick}>
      <CardWrapper>
        <FaPlus className="text-3xl md:text-5xl text-deep-purple-800" />
        <p className="text-center">Créer un utilisateur</p>
      </CardWrapper>
    </button>
  );
};
