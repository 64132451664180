import { ProfessionalResponse } from "./dto/professional.response";
import { RegisterProfessionalRequest } from "./dto/register-professional.request";
import { LoginRequest } from "./dto/login.request";
import { LoginResponse } from "./dto/login.response";
import { axiosClient, updateAuthHeader } from "../client";
import { RegisterCustomerRequest } from "./dto/register-customer.request";
import { CustomerResponse } from "./dto/customer.response";
import { MeResponse } from "./dto/me.response";

export class AuthApi {
    public static async login(body: LoginRequest): Promise<LoginResponse> {
        const response = await axiosClient.post<LoginResponse>('/auth/login', body);
        return response.data
    }

    public static async me(): Promise<MeResponse> {
        updateAuthHeader()
        const response = await axiosClient.get<MeResponse>('/auth/me');

        return response.data
    }

    public static async registerProfessional(body: RegisterProfessionalRequest): Promise<ProfessionalResponse> {
        const response = await axiosClient.post<ProfessionalResponse>('/professionals', body);
        return response.data
    }

    public static async registerCustomer(body: RegisterCustomerRequest) {
        const response = await axiosClient.post<CustomerResponse>('/customers', body);

        return response.data
    }
}