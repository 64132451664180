import { IPointData } from "@pixi/core";
import { Container, DisplayObject } from "@pixi/display";
import { EntityState } from "../../space/game/entities/state/EntityState";

export abstract class Entity {
    protected _container: Container
    protected _state: EntityState

    constructor() {
        this._container = new Container();
        this._state = EntityState.ALIVE
    }

    public get container(): Container {
        return this._container;
    }

    public get x(): number {
        return this._container.x
    }

    public get y(): number {
        return this._container.y
    }

    public get width(): number {
        return this._container.width
    }

    public get height(): number {
        return this._container.height
    }

    public get scale(): IPointData {
        return this._container.scale;
    }

    public get rotation(): number {
        return this._container.angle
    }

    public get pivot(): IPointData {
        return this._container.pivot
    }

    public get state(): EntityState {
        return this._state
    }

    public set x(x: number) {
        this._container.position.x = x
    }

    public set y(y: number) {
        this._container.position.y = y
    }

    public set width(width: number) {
        this._container.width = width;
    }

    public set height(height: number) {
        this._container.height = height;
    }

    public set scale(value: IPointData) {
        this._container.scale = value
    }

    public set rotation(rotation: number) {
        this._container.angle = rotation;
    }

    public set pivot(value: IPointData) {
        this._container.pivot = value;
    }

    public set state(state: EntityState) {
        this._state = state;
    }

    public abstract display(container: Container<DisplayObject>): void;

    public abstract update(delta: number): void
}