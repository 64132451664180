import { Application } from '@pixi/app';
import { useEffect, useRef } from 'react';
import { GameClass } from './GameClass';

export interface GameRenderConfig {
    fps: number;
}

interface GameRenderProps {
    gameClass: (engine: Application, container: HTMLDivElement) => GameClass
    width: number;
    height: number;
}



const GameRender = (props: GameRenderProps) => {
    const pixiContainer = useRef<HTMLDivElement>(null);
    const appRef = useRef<Application | null>(null);
    const game = useRef<GameClass | null>(null);

    useEffect(() => {
        const initEngine = async () => {
            const app = new Application({
                width: props.width,
                height: props.height,
                backgroundColor: 0x000000,
            });
            appRef.current = app;

            if (pixiContainer.current) {
                pixiContainer.current.innerHTML = '';
                pixiContainer.current.appendChild(app.view as HTMLCanvasElement);

                game.current = props.gameClass(app, pixiContainer.current);

                app.ticker.add((delta) => {
                    if (!game.current) {
                        return;
                    }
                    game.current.update(delta);
                });
            }
        };

        initEngine();

        pixiContainer.current?.focus();

        return () => {
            const app = appRef.current;
            if (app?.renderer) {
                app.destroy(true, true);
            }
            game.current?.destroy();
        };
    }, []);

    return <div tabIndex={0} ref={pixiContainer} />;
};

export default GameRender;

