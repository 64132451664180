import { Button, Input } from "@material-tailwind/react";
import { AxiosError } from "axios";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { AuthApi } from "../../api/auth/auth.api";
import { RegisterProfessionalRequest } from "../../api/auth/dto/register-professional.request";
import { RegisterCustomerRequest } from "../../api/auth/dto/register-customer.request";

interface State {
  firstName: string;
  lastName: string;
  email: string;
  phone: string;
  password: string;
  repeatPassword: string;
  profession: string;

  errors: string[];
}

const Register: React.FC = () => {
  const navigate = useNavigate();
  const [state, _setState] = useState<State>({
    firstName: "",
    lastName: "",
    email: "",
    phone: "",
    password: "",
    repeatPassword: "",
    profession: "",
    errors: [],
  });
  const [accountType, setAccountType] = useState<'professional' | 'customer'>('professional')
  const setState = (value: Partial<State>) =>
    _setState((prevState) => ({ ...prevState, ...value }));

  const handleUserRegister = () => {
    if (accountType === 'professional') {
      return AuthApi.registerProfessional({
        account: {
          email: state.email,
          password: state.password,
        },
        firstName: state.firstName,
        lastName: state.lastName,
        phone: state.phone,
        profession: "ergotherapeute",
      })
    }
    return AuthApi.registerCustomer({
      account: {
        email: state.email,
        password: state.password,
      },
      firstName: state.firstName,
      lastName: state.lastName,
      phone: state.phone,
    })
  }

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    if (state.password !== state.repeatPassword) {
      toast.error("Les mots de passe ne correspondent pas");
      setState({ errors: ["account.password"] });
      return;
    }
    
    handleUserRegister()
    .then(() => {
      toast.success("Inscription réussie");
      navigate("/sign-in");
    })
    .catch((e) => {
      if (!(e instanceof AxiosError)) {
        toast.error("Erreur lors de l'inscription");
        return;
      }
      if (
        e.response?.status === 400 &&
        e.response.data.message instanceof Array
      ) {
        setState({
          errors: e.response.data.message.map((m: string) => m.split(" ")[0]),
        });
        return toast.error("Certains champs sont invalides");
      }
      toast.error("Erreur lors de l'inscription");
    });
  };

  return (
    <div className="flex flex-col items-center justify-center flex-1 space-y-4 p-2">
      <div className="w-full max-w-[40em] relative">
        <img
          className="w-36 h-36 absolute -top-24 right-10 z-0"
          src="/assets/millo/millo-happy.png"
          alt="Millo"
        />
        <form
          className="p-10 bg-white rounded-xl w-full z-10 relative"
          onSubmit={handleSubmit}
        >
          <h1 className="text-3xl text-center mb-7">Bienvenue !</h1>
          <div className="flex flex-col gap-2 mb-3">
            <div className="flex gap-2">
              <Input
                size="lg"
                crossOrigin=""
                color="deep-purple"
                label="Prénom"
                type="text"
                value={state.firstName}
                onChange={(e) => setState({ firstName: e.target.value })}
                error={state.errors.includes("firstName")}
              />
              <Input
                size="lg"
                crossOrigin=""
                color="deep-purple"
                label="Nom"
                type="text"
                value={state.lastName}
                onChange={(e) => setState({ lastName: e.target.value })}
                error={state.errors.includes("lastName")}
              />
            </div>
            <hr className="my-3" />
            <Input
              size="lg"
              crossOrigin=""
              color="deep-purple"
              label="Email"
              type="email"
              value={state.email}
              onChange={(e) => setState({ email: e.target.value })}
              error={state.errors.includes("account.email")}
            />
            <Input
              size="lg"
              crossOrigin=""
              color="deep-purple"
              label="Numéro de téléphone"
              type="tel"
              value={state.phone}
              onChange={(e) => setState({ phone: e.target.value })}
              error={state.errors.includes("account.phone")}
            />
            <Input
              size="lg"
              crossOrigin=""
              color="deep-purple"
              label="Mot de passe"
              type="password"
              value={state.password}
              onChange={(e) => setState({ password: e.target.value })}
              error={state.errors.includes("account.password")}
            />

            <Input
              size="lg"
              crossOrigin=""
              color="deep-purple"
              label="Répéter le Mot de passe"
              type="password"
              value={state.repeatPassword}
              onChange={(e) => setState({ repeatPassword: e.target.value })}
              error={state.errors.includes("account.password")}
            />
            <hr className="my-3" />
            <select
              className="p-2 border rounded focus:outline-none focus:ring-deep-purple-500 focus:border-deep-purple-500"
              value={accountType}
              onChange={(e) => setAccountType(e.target.value as ('professional' | 'customer'))}
            >
              <option value="" disabled>
                -- Sélectionnez votre type de compte --
              </option>
              <option value="professional">Ergothérapeute</option>
              <option value="customer">Joueur</option>
            </select>
          </div>
          <div className="flex items-center justify-center">
            <Button color="deep-purple" type="submit" size="lg">
              S'inscrire
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Register;
