import { Sprite } from "@pixi/sprite";
import { ObservablePoint } from "@pixi/core";
import { EntityState } from "./state/EntityState";
import { ExplosionComponent } from "../component/ExplosionComponent";
import { Container, DisplayObject } from "@pixi/display";
import WordEntity from "../../../common/entities/WordEntity";
import { Entity } from "../../../common/entities/Entity";

export class EnemyEntity extends Entity {
    private _sprite: Sprite;
    private _speed: number;
    private _wordEntity: WordEntity
    private _deathExplosion: ExplosionComponent

    constructor(
        sprite: Sprite,
        word: string,
        speed: number,
        screenWidth: number
    ) {
        super();
        
        this._sprite = sprite;
        this._wordEntity = new WordEntity(word, screenWidth)
        this._speed = speed;
        this._deathExplosion = new ExplosionComponent({ x: 0, y: 0 })

        this._sprite.texture.baseTexture.on('loaded', () => {
            this._wordEntity.get().position.y = this._sprite.y + (this._sprite.width * 0.80);
        })
        if (this._sprite.texture.baseTexture.valid) {
            this._wordEntity.get().position.y = this._sprite.y + (this._sprite.width * 0.80);
        }
        this._sprite.scale.x = 0.75;
        this._sprite.scale.y = 0.75;

        this._deathExplosion.container.scale.x = 2;
        this._deathExplosion.container.scale.y = 2;
    }   

    public get speed(): number {
        return this._speed;
    }

    public get anchor(): ObservablePoint {
        return this._sprite.anchor;
    }

    public get angle(): number {
        return this._sprite.angle;
    }

    public set typed(typed: string) {
        this._wordEntity.typed = typed;
    }

    public set anchor(anchor: ObservablePoint) {
        this._sprite.anchor = anchor;
    }

    public set angle(angle: number) {
        this._sprite.angle = angle;
    }

    public tryHit(letter: string): boolean {
        if (this.state !== EntityState.ALIVE) {
            return false;
        }
        return this._wordEntity.tryLockHit(letter);
    }

    public hit(letter: string): void {
        this._wordEntity.typed += letter;

        if (this._wordEntity.text.text === this._wordEntity.typed) {
            this.state = EntityState.DEATH_BEGIN;
        }
    }

    public display(container: Container<DisplayObject>): void {
        this._container.addChild(this._sprite);
        this._container.addChild(this._wordEntity.get())
        this._container.addChild(this._deathExplosion.container)
        container.addChild(this._container);
    }

    public update(delta: number) {
        switch (this.state) {
            case EntityState.ALIVE:
                this._wordEntity.update();
                break;

            case EntityState.DEATH_BEGIN:
                this._container.removeChild(this._sprite);
                this._container.removeChild(this._wordEntity.get())
                this._deathExplosion.emitter.emit = true;
                this.state = EntityState.DEATH_BEING;
                break;

            case EntityState.DEATH_BEING:
                if (!this._deathExplosion.emitter.emit) {
                    this.state = EntityState.DEATH_END;
                    return;
                }
                this._deathExplosion.emitter.update(delta * 0.01);
                break;

            default:
                break;
        }
    }
}