import { Sprite } from "@pixi/sprite";

export class SpriteCache {
    static get background() {
        return Sprite.from('/assets/space/background.jpg');
    }

    static get player() {
        return Sprite.from('/assets/space/spaceship_1.png');
    }

    static get enemie() {
        return Sprite.from('/assets/space/comet.png');
    }

    static get hearth() {
        return Sprite.from('/assets/space/hearth.png')
    }
} 