import { useRef, useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { LunchGameResponse } from "../../api/game/dto/lunch-game.response";
import GameEnd from "../../components/game/GameEnd";
import GameSettings from "../../components/game/GameSettings";
import GameWindow from "../../components/game/GameWindow";
import { GameStatus } from "../../domain/game/GameStatus";
import { Button } from "@material-tailwind/react";
import { FaChevronLeft } from "react-icons/fa";
import GameLevelSettings from "../../components/game/GameLevelSettings";

const Game = () => {
  const { playerId, gameType, mode } = useParams<{
    playerId: string;
    gameType: string;
    mode: "sandbox" | "adventure";
  }>();
  const [gameStatus, setGameStatus] = useState(GameStatus.SELECT_PARAMS);
  const [gameData, setGameData] = useState({ instanceId: "", type: "" });
  const [gameEndData, setGameEndData] = useState({ message: "" });
  const [fullScreenMode, setFullScreenMode] = useState<boolean>(false);

  const containerRef = useRef<HTMLDivElement>(null);
  const [scale, setScale] = useState(1);

  const calculateScale = (width: number, height: number) => {
    const containerWidth = width - 20;
    const containerHeight = height - 40;
    const aspectRatio = 16 / 9;

    let bestWidth = 0;
    if (containerWidth / containerHeight > aspectRatio) {
      bestWidth = containerHeight * aspectRatio;
    } else {
      bestWidth = containerWidth;
    }

    return bestWidth / 1280;
  };

  useEffect(() => {
    if (!containerRef.current) return () => { };
    const observer = new ResizeObserver(entries => {
      setScale(calculateScale(entries[0].contentRect.width, entries[0].contentRect.height));
    })
    observer.observe(containerRef.current)
    return () => containerRef.current && observer.unobserve(containerRef.current)
  }, [])

  const handleGameSelected = (data: LunchGameResponse) => {
    setGameStatus(GameStatus.STARTED);
    setGameData({
      type: data.settings.type,
      instanceId: data.id,
    });
    if (data.settings?.fullscreenMode) {
      setFullScreenMode(true);
    }
  };

  const handleGameEnd = (message: string) => {
    setGameStatus(GameStatus.FINISHED);
    setGameEndData({ message: message });
    setFullScreenMode(false);
  };

  const handleGameRestart = () => {
    setGameStatus(GameStatus.SELECT_PARAMS);
  };

  return (
    <div className="flex flex-col px-10 min-h-full" ref={containerRef}>
      <Button onClick={() => window.history.back()} variant="outlined" className="self-start mb-5">
        <FaChevronLeft className="inline align-middle" />
        <span className="align-middle ml-2">
          Retour
        </span>
      </Button>
      <div
        className={`w-[1280px] h-[720px] bg-gradient-to-t from-indigo-200 to-indigo-300 rounded-md shadow-md overflow-y-scroll no-scrollbar`}
        style={{ transform: `scale(${scale.toFixed(2)})`, transformOrigin: 'top left' }}
      >
        {gameStatus === GameStatus.SELECT_PARAMS && playerId && gameType && mode === 'sandbox' &&  (
          <GameSettings
            gameType={gameType}
            playerId={playerId}
            onValidate={handleGameSelected}
          />
        )}
        {gameStatus === GameStatus.SELECT_PARAMS && playerId && gameType && mode === 'adventure' &&  (
          <GameLevelSettings
            gameType={gameType}
            playerId={playerId}
            onValidate={handleGameSelected}
          />
        )}
        {gameStatus === GameStatus.STARTED && (
          <GameWindow
            type={gameData.type}
            instanceId={gameData.instanceId}
            fullscreenMode={fullScreenMode}
            onGameEnd={handleGameEnd}
          />
        )}
        {gameStatus === GameStatus.FINISHED && (
          <GameEnd message={gameEndData.message} onClick={handleGameRestart} />
        )}
      </div>
    </div>
  );
};

export default Game;
