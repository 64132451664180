import { useState } from "react";
import { PatientApi } from "../../api/patient/patient.api";
import { Button, Input } from "@material-tailwind/react";

const CreatePatientForm = (props: { onUpdate?: () => void }) => {
  const [firstname, setFirstname] = useState<string>("");
  const [lastname, setLastname] = useState<string>("");
  const handleSubmit = () => {
    PatientApi.createPatient({
      firstname: firstname,
      lastname: lastname,
      // color: "#FF6F61",
    } as any).then(() => {
      setFirstname("");
      setLastname("");
      if (props.onUpdate) {
        props.onUpdate();
      }
    });
  };

  return (
    <div className="p-5">
      <form>
        <h2 className="text-3xl text-center mb-7">Créer un utilisateur</h2>
        <div className="flex flex-col items-center gap-2">
          <Input
            crossOrigin=""
            label="Prénom"
            type="text"
            value={firstname}
            onChange={(e) => setFirstname(e.target.value)}
            color="deep-purple"
          />
          <Input
            crossOrigin=""
            label="Nom"
            type="text"
            value={lastname}
            onChange={(e) => setLastname(e.target.value)}
            color="deep-purple"
          />
          <Button onClick={handleSubmit} color="deep-purple" className="mt-3">
            Créer
          </Button>
        </div>
      </form>
    </div>
  );
};

export default CreatePatientForm;
