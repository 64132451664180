import { Application } from "@pixi/app";
import { EnemyFactory } from "../init/EnemyFactory";
import { EnemyEntity } from "../entities/EnemyEntity";
import { SpaceGameConfiguration } from "../SpaceGame";
import { RandomNumberHelper } from "../../helpers/RandomNumberHelper";

export class SpawnEnemySystem {
    private engine: Application;
    private config: SpaceGameConfiguration;
    private enemyFactory: EnemyFactory;

    constructor(
        engine: Application,
        config: SpaceGameConfiguration
    ) {
        this.engine = engine;
        this.enemyFactory = new EnemyFactory(this.engine);
        this.config = config;
    }

    private comptEnemiesOnScreen(enemies: EnemyEntity[]): number {
        let compt = 0;
        const screen = this.engine.screen;

        for (const enemy of enemies) {
            if (enemy.y < screen.height && enemy.x < screen.width) {
                compt++;
            }
        }
        return compt;
    }

    private spawnEnemy(enemies: EnemyEntity[]) {
        const wordIndex = RandomNumberHelper.generateNumberInteger(
            0,
            this.config.words.length - 1
        )
        const enemy = this.enemyFactory.generate({
            word: this.config.words[wordIndex],
            speed: this.config.enemySpeed
        })

        enemies.push(enemy);
        enemy.display(this.engine.stage);
    }

    public handle(enemies: EnemyEntity[]): void {
        const enemiesToCreate = this.config.enemyNumber -
            this.comptEnemiesOnScreen(enemies);

        if (enemiesToCreate === 0) {
            return;
        }
        if (enemies.length === 0) {
            this.spawnEnemy(enemies);
            return;
        }

        const lastEnemy = enemies[enemies.length - 1];

        if (lastEnemy.y >= this.config.enemySpacing) {
            this.spawnEnemy(enemies);
            return;
        }
    }
}