import { Button } from "@material-tailwind/react";
import { FaChevronLeft, FaChevronRight, FaHome, FaSignOutAlt, FaUsers } from "react-icons/fa";
import { Link, useLocation } from "react-router-dom";
import { useSession } from "../providers/SessionProvider";
import React, { useEffect, useState } from "react";
import { AccountType } from "../api/auth/type/account.type";

const PROFESIONAL_PAGES = [
  {
    "path": "/",
    "name": "Accueil",
    "icon": FaHome
  },
  {
    "path": "/patient",
    "name": "Patients",
    "icon": FaUsers
  }
]

const CUSTOMER_PAGES = [
  {
    "path": "/",
    "name": "Accueil",
    "icon": FaHome
  },
]

const PATIENT_PAGES = (id: string) => [
  {
    "path": `/patient/${id}`,
    "name": "Profil",
    "icon": FaUsers
  },
  {
    "path": `/patient/${id}/game`,
    "name": "Jeux",
    "icon": FaUsers
  }
]

interface DrawerProps {
  pagesButtons: React.ReactNode;
  bottomButtons: React.ReactNode;
}

const Drawer: React.FC<DrawerProps> = ({ pagesButtons, bottomButtons }) => {
  const [isOpen, setIsOpen] = useState(true); // State to manage drawer visibility

  const toggleDrawer = () => {
    setIsOpen((prev) => !prev);
  };

  return (
    <div className={`flex flex-col bg-white border-r-gray-50 border-r-2 p-5 transition-all duration-300 ${isOpen ? 'w-80' : 'w-16'}`}>
      {/* Toggle Button */}
      <button onClick={toggleDrawer} className="self-end mb-2">
        {isOpen ? <FaChevronLeft /> : <FaChevronRight />}
      </button>
      <div className="flex flex-col justify-between flex-1">
        <div className={`flex flex-col transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}>
          <Link to="/">
            <div className="flex gap-1 items-center">
              <img className="mr-2 h-12" src="/assets/millo-logo.png" alt="logo" />
              <h1 className={`text-2xl text-black ${isOpen ? '' : 'hidden'}`}>
                <span className="font-black">Millo</span> Ergothérapie
              </h1>
            </div>
          </Link>

          {isOpen && pagesButtons} {/* Render pagesButtons only if open */}
        </div>

        <div className={`transition-opacity duration-300 ${isOpen ? 'opacity-100' : 'opacity-0'}`}>
          {isOpen && bottomButtons} {/* Render bottomButtons only if open */}
        </div>
      </div>
    </div>
  );
};


export const ProfessionalDrawer: React.FC = () => {
  const { pathname } = useLocation();
  const { signOut } = useSession();
  const [ currentAccountType, setCurrentAccountType ] = useState<string>(
    AccountType.CUSTOMER
  )

  useEffect(() => {
    const accountType = localStorage.getItem('AccountType');

    if (accountType !== null) {
      setCurrentAccountType(accountType);
    }
  }, [])


  return (
    <Drawer
      pagesButtons={
        <div className="flex flex-col gap-5 mt-5">
          {currentAccountType === AccountType.PROFESSIONAL && PROFESIONAL_PAGES.map((page) => (
            <Link to={page.path} key={page.path}>
              <Button
                className="flex gap-1 items-center w-full"
                variant="text"
                size="lg"
                color={page.path === pathname ? "deep-purple" : "gray"}
              >
                <page.icon />
                {page.name}
              </Button>
            </Link>
          ))}
          {currentAccountType !== AccountType.PROFESSIONAL && CUSTOMER_PAGES.map((page) => (
            <Link to={page.path} key={page.path}>
              <Button
                className="flex gap-1 items-center w-full"
                variant="text"
                size="lg"
                color={page.path === pathname ? "deep-purple" : "gray"}
              >
                <page.icon />
                {page.name}
              </Button>
            </Link>
          ))}
        </div>
      }
      bottomButtons={
        <Button
          className="flex gap-1 items-center w-full"
          variant="text"
          size="lg"
          color="gray"
          onClick={signOut}
        >
          <FaSignOutAlt />
          Déconnexion
        </Button>
      }
    />
  );
}

export const PatientDrawer: React.FC = () => {
  const { pathname } = useLocation();
  const id = pathname.split("/")[2];
  const pages = PATIENT_PAGES(id);

  return (
    <Drawer
      pagesButtons={
        <div className="flex flex-col gap-5 mt-5">
          {pages.map((page) => (
            <Link to={page.path} key={page.path}>
              <Button
                className="flex gap-1 items-center w-full"
                variant="text"
                size="lg"
                color={page.path === pathname ? "amber" : "gray"}
              >
                <page.icon />
                {page.name}
              </Button>
            </Link>
          ))}
        </div>
      }
      bottomButtons={
        <Link to="/">
          <Button
            className="flex gap-1 items-center w-full text-white"
            size="lg"
            color="amber"
          >
            <FaSignOutAlt />
            Retour à l'accueil ergo
          </Button>
        </Link>
      }
    />
  );
}

