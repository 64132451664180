import { Outlet } from "react-router-dom";
import { ToastContainer, Zoom } from "react-toastify";
import { PatientDrawer, ProfessionalDrawer } from "./components/Drawer";
import Navbar from "./components/Navbar";

export const NavBarLayout = () => {
  return (
    <>
      <div className="flex flex-col min-h-screen bg-deep-purple-50">
        <Navbar />
        <main className="flex flex-col flex-1">
          <Outlet />
        </main>
      </div>
      <ToastContainer
        position="bottom-center"
        hideProgressBar={true}
        pauseOnHover
        theme="light"
        stacked
        transition={Zoom}
      />
    </>
  );
};

export const DrawerProfessionalLayout = () => {
  return (
    <>
      <main className="flex w-screen h-screen bg-deep-purple-50">
        <ProfessionalDrawer />
        <div className="flex flex-col flex-1 p-5 overflow-y-auto">
          <Outlet />
        </div>
      </main>
      <ToastContainer
        position="bottom-center"
        hideProgressBar={true}
        pauseOnHover
        theme="light"
        stacked
        transition={Zoom}
      />
    </>
  );
}

export const DrawerPatientLayout = () => {
  return (
    <>
      <main className="flex w-screen h-screen bg-amber-50">
        <PatientDrawer />
        <div className="flex flex-col flex-1 p-5 overflow-y-auto">
          <Outlet />
        </div>
      </main>
      <ToastContainer
        position="bottom-center"
        hideProgressBar={true}
        pauseOnHover
        theme="light"
        stacked
        transition={Zoom}
      />
    </>
  );
}
