import React, {
  createContext,
  useContext,
  useState,
  useEffect,
  ReactNode,
} from "react";
import { updateAuthHeader } from "../api/client";

// Define the interface for the session context
interface SessionContextProps {
  signIn: (token: string, refreshToken: string) => void;
  signOut: () => void;
  isSignedIn: boolean;
  getToken: () => string | null;
}

const SessionContext = createContext<SessionContextProps | undefined>(
  undefined
);

// Define the keys for localStorage
const TOKEN_KEY = "bearer";
const REFRESH_TOKEN_KEY = "refresh_token";

// Function to get token outside of a React component
export const getToken = (): string | null => {
  return localStorage.getItem(TOKEN_KEY);
};

// Function to get refresh token outside of a React component
export const getRefreshToken = (): string | null => {
  return localStorage.getItem(REFRESH_TOKEN_KEY);
};

export const updateTokens = (token: string, refreshToken: string) => {
  localStorage.setItem(TOKEN_KEY, token);
  localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
};

export const clearTokens = () => {
  localStorage.removeItem(TOKEN_KEY);
  localStorage.removeItem(REFRESH_TOKEN_KEY);
}

// SessionProvider component
export const SessionProvider = ({ children }: { children: ReactNode }) => {
  const [token, setToken] = useState<string | null>(getToken());
  const [refreshToken, setRefreshToken] = useState<string | null>(
    getRefreshToken()
  );

  const updateTokens = (token: string | null, refreshToken: string | null) => {
    if (token) {
      localStorage.setItem(TOKEN_KEY, token);
    } else {
      localStorage.removeItem(TOKEN_KEY);
    }
    updateAuthHeader();

    if (refreshToken) {
      localStorage.setItem(REFRESH_TOKEN_KEY, refreshToken);
    } else {
      localStorage.removeItem(REFRESH_TOKEN_KEY);
    }
  };

  useEffect(() => {
    updateTokens(token, refreshToken);
  }, [token, refreshToken]);

  const signIn = (newToken: string, newRefreshToken: string) => {
    updateTokens(newToken, newRefreshToken);
    setToken(newToken);
    setRefreshToken(newRefreshToken);
  };

  const signOut = () => {
    setToken(null);
    setRefreshToken(null);
    localStorage.removeItem(TOKEN_KEY);
    localStorage.removeItem(REFRESH_TOKEN_KEY);
  };

  const isSignedIn = !!token;

  return (
    <SessionContext.Provider value={{ signIn, signOut, isSignedIn, getToken }}>
      {children}
    </SessionContext.Provider>
  );
};

// Custom hook to use session context in components
export const useSession = (): SessionContextProps => {
  const context = useContext(SessionContext);
  if (context === undefined) {
    throw new Error("useSession must be used within a SessionProvider");
  }
  return context;
};
