import { GameType } from "./GameType";

export class GameHandler {
    getGameUrl(instanceId: string, type: string) {
        switch (type) {
            case GameType.DragAndDropGame:
                return '/game/drag-and-drop-game/index.html?id=' + instanceId;
            case GameType.HunterGameTargetMode:
                return '/game/hunter-game/index.html?id=' + instanceId;
            case GameType.HunterGameTimeMode:
                return '/game/hunter-game/index.html?id=' + instanceId;
            case GameType.HunterGameTimeTargetMode:
                return '/game/hunter-game/index.html?id=' + instanceId;
            case GameType.PlatformerGame:
                return '/game/platformer-game/index.html?id=' + instanceId;
            case GameType.SpaceGame:
                return '/space/instance/' + instanceId;
            case GameType.BoatGame:
                return '/game/boat-game/index.html?id=' + instanceId;
            case GameType.SoupGame:
                return '/soup/instance/' + instanceId;
            default:
                return undefined;
        }
    }
}