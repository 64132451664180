import { useState } from "react";
import { AuthApi } from "../../api/auth/auth.api";
import { useNavigate } from "react-router-dom";
import { Button, Checkbox, Input } from "@material-tailwind/react";
import { useSession } from "../../providers/SessionProvider";
import { toast } from "react-toastify";
import { AccountType } from "../../api/auth/type/account.type";

const Login: React.FC = () => {
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const { signIn } = useSession();
  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    AuthApi.login({
      email: email,
      password: password,
    })
      .then((response) => {
        signIn(response.access_token, response.refresh_token);
        localStorage.setItem('AccountType', response.type);
        if (response.type === AccountType.PROFESSIONAL) {
          navigate("/patient");
        } else {
          navigate("/");
        }
      })
      .catch(() => {
        toast.error("Email ou mot de passe incorrect");
      });
  };

  return (
    <div className="flex flex-col items-center justify-center flex-1 space-y-4 p-2">
      <div className="w-full max-w-[40em] relative">
        <img
          className="w-36 h-36 absolute -top-24 left-10 z-0"
          src="/assets/millo/millo-happy.png"
          alt="Millo"
        />
        <form
          className="p-10 bg-white rounded-xl w-full z-10 relative"
          onSubmit={handleSubmit}
        >
          <h1 className="text-3xl text-center mb-7">Bon retour !</h1>
          <div className="flex flex-col gap-2 mb-3">
            <Input
              size="lg"
              crossOrigin=""
              color="deep-purple"
              label="Email"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div>
              <Input
                size="lg"
                crossOrigin=""
                color="deep-purple"
                label="Mot de passe"
                type="password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <p className="text-sm text-right text-black cursor-pointer">
                Mot de passe oublié ?
              </p>
            </div>
            <div className="flex items-center justify-between">
              <div className="flex items-center">
                <Checkbox color="deep-purple" crossOrigin="" id="remember-me" />
                <label htmlFor="remember-me">Se souvenir de moi</label>
              </div>
            </div>
          </div>
          <div className="flex items-center justify-center">
            <Button color="deep-purple" type="submit" size="lg">
              Se connecter
            </Button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default Login;
