import React from "react";
import { WidgetProps } from "@rjsf/utils";
import { t } from "../../../translate";
import CartoonSwitch from "../cartoon/Switch";

const CheckboxWidget = (props: WidgetProps) => {
  const { label, value, required, disabled, readonly, onChange } = props;

  return (
    <div className="flex items-center">
      <div className="w-16 h-8 mr-2">
        <CartoonSwitch
          value={value}
          required={required}
          disabled={disabled || readonly}
          onChange={(e) => onChange(e)}
        />
      </div>
      <label className="cartoon text-2xl text-white">{t(label)}</label>
    </div>
  );
};

export default CheckboxWidget;
