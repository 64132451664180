import { EnemyEntity } from "../entities/EnemyEntity";
import { SpriteCache } from "./SpriteCache";
import { Application } from "@pixi/app";
import { RandomNumberHelper } from "../../helpers/RandomNumberHelper";

export interface EnemyParameters {
    word: string;
    speed: number;
}

export class EnemyFactory {
    constructor(
        private readonly engine: Application
    ) {}

    private generateEnityParameters(parameters: EnemyParameters): EnemyEntity {
        const enemy = new EnemyEntity(
            SpriteCache.enemie,
            parameters.word,
            parameters.speed,
            this.engine.screen.width
        )

        enemy.container.scale.x = 0.25;
        enemy.container.scale.y = 0.25;
        enemy.anchor.set(0.5, 0.5);
        enemy.container.x = RandomNumberHelper.generateNumberFloat(0, this.engine.screen.width);
        enemy.container.y = -enemy.container.height;
        enemy.container.pivot.set(
            enemy.container.width / 2,
            enemy.container.height / 2
        );
        return enemy;
    }

    public generate(parameters: EnemyParameters): EnemyEntity {
        return this.generateEnityParameters(
            parameters
        )
    }
} 