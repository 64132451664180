import { AngleHelper } from "../../helpers/AngleHelper";
import { BulletEntity } from "../entities/BulletEntity";
import { EnemyEntity } from "../entities/EnemyEntity";
import { PlayerEntity } from "../entities/PlayerEntity";

export class PlayerShootSystem {
    private nextTargets: BulletEntity[];

    constructor(
        private readonly playerEntity: PlayerEntity
    ) {
        this.nextTargets = [];
    }

    private spawnBullet(enemy: EnemyEntity, word: string) {
        const position = {
            x: this.playerEntity.x,
            y: this.playerEntity.y - (this.playerEntity.height / 2)
        }

        return new BulletEntity(
            position,
            enemy,
            10,
            word
        )
    }

    public handle(word: string, enemies: EnemyEntity[]) {
        if (enemies.length === 0) {
            return;
        }
        for (const enemy of enemies) {
            if (enemy.y + enemy.height < 0) {
                continue;
            }
            if (enemy.tryHit(word)) {
                this.nextTargets.push(
                    this.spawnBullet(enemy, word)
                );
                return;
            }
        }
    }

    public pullNextTarget(): BulletEntity | undefined {
        return this.nextTargets.shift();
    }

    public aim(bulletEntity: BulletEntity) {
        this.playerEntity.container.angle = AngleHelper.computeAngle(
            this.playerEntity.container.position,
            bulletEntity.target.container.position
        )

        const relativeX = bulletEntity.x - this.playerEntity.x;
        const relativeY = bulletEntity.y - this.playerEntity.y;
        const rotation = this.playerEntity.container.rotation;
    
        const rotatedX = relativeX * Math.cos(rotation) - relativeY * Math.sin(rotation);
        const rotatedY = relativeX * Math.sin(rotation) + relativeY * Math.cos(rotation);
    
        
        bulletEntity.x = this.playerEntity.x + rotatedX 
        bulletEntity.y = this.playerEntity.y + rotatedY
    }
}