import React from 'react';

const CartoonButton: React.FC<{ value: string, onClick?: () => void }> = ({ value, onClick }) => {
  return (
    <button
      onClick={onClick}
      className="mx-auto block bg-gradient-to-r from-indigo-200 to-indigo-300 p-1 rounded-full p"
    >
      <div className='cartoon text-3xl text-white w-full bg-indigo-900 text-center bg-opacity-50 outline-none rounded-full py-3 px-20'>
        {value}
      </div>
    </button>
  );
};

export default CartoonButton;