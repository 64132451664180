import { ObjectFieldTemplateProps } from "@rjsf/utils";
import { t } from "../../../translate";

const ObjectFieldTemplate = (props: ObjectFieldTemplateProps) => {

    return (
        <div>
            <label className="cartoon text-2xl text-white text-center">{t(props.title)}</label>
            <div className="grid gap-4 grid-cols-2 ">
                {props.properties.map((prop) => prop.content)}
            </div>
        </div>
    );
}

export default ObjectFieldTemplate;