import { BulletEntity } from "../entities/BulletEntity";
import { SpaceGameEntity } from "../entities/SpaceGameEntity";
import { EntityState } from "../entities/state/EntityState";

export class BulletSystem {
    constructor(
        private readonly gameRules: SpaceGameEntity
    ) {}

    private computeNextPosition(
        delta: number,
        bullet: BulletEntity,
        distanceX: number,
        distanceY: number
    ) {
        const bulletPosition = bullet.container.position;
        const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);

        if (distance === 0) {
            return;
        }

        bullet.container.position.set(
            bulletPosition.x + (distanceX / distance) * bullet.speed * delta,
            bulletPosition.y + (distanceY / distance) * bullet.speed * delta
        )
    }

    private computeAngle(
        bullet: BulletEntity,
        distanceX: number,
        distanceY: number
    ) {
        bullet.container.angle = Math.atan2(distanceX, distanceY) * (180 / Math.PI) * -1;
    }

    private checkCollision(bullet: BulletEntity) {
        const bulletPosition = bullet.container.position;
        const targetPosition = bullet.target.container.position;

        const minX = targetPosition.x - bullet.target.width;
        const maxX = targetPosition.x + bullet.target.width;

        const minY = targetPosition.y - bullet.target.height;
        const maxY = targetPosition.y + bullet.target.height;

        const checkX = bulletPosition.x >= minX && bulletPosition.x <= maxX;
        const checkY = bulletPosition.y >= minY && bulletPosition.y <= maxY;



        if (checkX && checkY) {
            bullet.hit();
            this.gameRules.countLetter();
            bullet.state = EntityState.DEATH_END;

            if (bullet.target.state !== EntityState.ALIVE) {
                this.gameRules.countWord();
            }
        }
    }

    public handle(delta: number, bullet: BulletEntity) {
        const distanceX = bullet.target.container.position.x - bullet.container.x;
        const distanceY = bullet.target.container.position.y - bullet.container.y;

        this.computeNextPosition(delta, bullet, distanceX, distanceY);
        this.computeAngle(bullet, distanceX, distanceY);
        this.checkCollision(bullet);
    }
}