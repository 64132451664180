import { Container } from "@pixi/display";
import { Emitter, EmitterConfigV3 } from "@pixi/particle-emitter";
import { GameEmitter } from "../../../engine/entities";

const EMITTER_CONFIG = (width: number, pos: { x: number; y: number;}, color: string): EmitterConfigV3 => ({
    lifetime: {
        min: 1 / 5,
        max: 1 / 2
    },
    frequency: 0.001,
    emitterLifetime: 0.1,
    maxParticles: 80,
    pos,
    behaviors: [
        {
            type: "alpha",
            config: {
                alpha: {
                    list: [
                        {
                            time: 0,
                            value: 0.1
                        },
                        {
                            time: 1,
                            value: 0
                        }
                    ]
                }
            }
        },
        {
            type: "scale",
            config: {
                scale: {
                    list: [
                        {
                            time: 0,
                            value: 2
                        },
                        {
                            time: 1,
                            value: 5
                        }
                    ]
                },
                minMult: 1
            }
        },
        {
            type: "colorStatic",
            config: {
                color
            }
        },
        {
            type: "rotation",
            config: {
                accel: 0,
                minSpeed: 0,
                maxSpeed: 200,
                minStart: 0,
                maxStart: 360
            }
        },
        {
            type: "textureRandom",
            config: {
                textures: [
                    "/assets/particles/particle.png"
                ]
            }
        },
        {
            type: "spawnPoint",
            config: {
                type: 'rect',
                data: {
                    x: 0,
                    y: 0,
                    w: width,
                    h: 20
                }
            }
        },
        {
            type: "moveAcceleration",
            config: {
                accel: {
                    x: 0,
                    y: -7000
                },
                minStart: 500,
                maxStart: 1000,
                rotate: true
            }
        },

    ]
});

class DestroySmokeEmitter implements GameEmitter {
    private emitter: Emitter;

    constructor(container: Container, wordWidth: number, pos: { x: number; y: number }, color: string) {
        this.emitter = new Emitter(container, EMITTER_CONFIG(wordWidth, pos, color));
        this.emitter.emit = true;
    }

    get() {
        return this.emitter;
    }

}


export default DestroySmokeEmitter;