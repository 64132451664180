import { Button } from "@material-tailwind/react";
import { Game } from "../../constants/games";
import { useNavigate } from "react-router-dom";
import { useModal } from "../../providers/ModalProvider";


export const GameDetails = (props: Readonly<{ playerId: string, game: Game }>) => {
    const { game, playerId } = props;
    const { closeModal } = useModal();
    const navigate = useNavigate();

    const startGame = (mode: "sandbox" | "adventure") => {
        closeModal();
        navigate(`/game/${game.enum}/${mode}/player/${playerId}`)
    }

    return (
        <div className="flex w-full max-w-[900px] gap-5">
            <div className="pl-5 pt-5 flex flex-col justify-between">
                <div>
                    <div className="flex gap-4 items-center">
                        <div className="text-5xl text-deep-purple-700">
                            {game.icon}
                        </div>
                        <div>
                            <h3 className="text-2xl">{game.name}</h3>
                            <p className="text-deep-purple-300">{game.gameType}</p>
                        </div>
                    </div>
                    <hr className="my-5" />
                    <div>
                        {game.description}
                    </div>
                </div>
                <div className="flex gap-3 justify-center">
                    <Button variant="text" color="deep-purple" size="lg" onClick={() => startGame("sandbox")}>
                        Entrainement
                    </Button>
                    <Button variant="filled" color="deep-purple" size="lg" className="px-10" onClick={() => startGame("adventure")}>
                        Jouer
                    </Button>
                </div>
            </div>
            <img
                src={game.image ?? "/assets/illustrations/no-image.jpg"}
                alt={game.name}
                className="w-96 h-full min-h-[400px] object-cover -mr-5 -mt-5 -mb-5"
            />
        </div>
    )
};
