import { authenticatedClient, updateAuthHeader } from "../client";
import { LunchGameRequest } from "./dto/lunch-game.request";
import { LunchGameResponse } from "./dto/lunch-game.response";

export default class GameApi {

    public static async lunch(request: LunchGameRequest) {
        updateAuthHeader();
        return authenticatedClient.post<LunchGameResponse>(
            '/games',
            request,)
    }
}