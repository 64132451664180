import { Switch } from "@material-tailwind/react";
import React from 'react';

interface CartoonSwitchProps {
  value?: boolean;
  required?: boolean;
  disabled?: boolean;
  readonly?: boolean;
  onChange: (checked: boolean) => void;
}

const CartoonSwitch: React.FC<CartoonSwitchProps> = ({ value, required, disabled, readonly, onChange }) => {
  return (
    <Switch
      crossOrigin=""
      checked={typeof value === "undefined" ? false : value}
      disabled={disabled || readonly}
      onChange={(e) => onChange(e.target.checked)}
      color="indigo"
      className="cartoon-switch relative inline-flex items-center w-16 h-8 bg-indigo-300 rounded-full peer-checked:bg-indigo-700 transition-all duration-300 ease-in-out"
    />
  );
};

export default CartoonSwitch;
