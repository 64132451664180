export class FontHelper {
    private static checkFontLoaded(fontName: string): boolean {
        return document.fonts.check(`1em ${fontName}`);
    }

    private static async loadFont(fontName: string): Promise<void> {
        await document.fonts.load(`1em ${fontName}`);
    }

    public static async waitFontToLoad(fontName: string) {
        await this.loadFont(fontName);
    }
}