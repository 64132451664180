import React from "react";
import 'react-circular-progressbar/dist/styles.css';
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import "./index.css";
import Login from "./pages/auth/Login";
import Patient from "./pages/patient/Patient";
import { SessionProvider, useSession } from "./providers/SessionProvider";
import reportWebVitals from "./reportWebVitals";

import "react-toastify/dist/ReactToastify.css";
import Register from "./pages/auth/Register";
import NotFound from "./pages/common/NotFound";
import Game from "./pages/game/Game";
import PatientPage from "./pages/patient/PatientPage";
import { ModalProvider } from "./providers/ModalProvider";

import { DrawerPatientLayout, DrawerProfessionalLayout, NavBarLayout } from "./layout";
import PatientGames from "./pages/patient/PatientGames";
import SpaceGameRender from "./game/space/SpaceGameRender";
import './translate';
import HomeErgo from "./pages/ergo/Home";
import SoupGameRender from "./game/soup/SoupGame";

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const AuthenticatedRoutes = (
  <React.Fragment>
    <Route path="/space/instance/:instanceId" element={<SpaceGameRender />} />
    <Route path="/soup/instance/:instanceId" element={<SoupGameRender />} />
    <Route element={<DrawerProfessionalLayout />}>
      <Route path="/" element={<HomeErgo />} />
      <Route path="/patient" element={<Patient />} />
      <Route path="*" element={<NotFound />} />
    </Route>
    <Route path="/patient" element={<DrawerPatientLayout />}>
      <Route path=":patientId" element={<PatientPage />} />
      <Route path=":patientId/game/" element={<PatientGames />} />
      <Route path="*" element={<NotFound />} />
    </Route>
    <Route path="/game">
      <Route path=":gameType/:mode/player/:playerId" element={<Game />}></Route>
    </Route>
  </React.Fragment>
);

const UnauthenticatedRoutes = (
  <Route element={<NavBarLayout />}>
    <Route path="/sign-in" element={<Login />} />
    <Route path="/sign-up" element={<Register />} />
    <Route path="*" element={<NotFound />} />
  </Route>
);

const AppRouter = () => {
  const { isSignedIn } = useSession();
  return (
    <BrowserRouter>
      <ModalProvider>
        <Routes>
          {isSignedIn ? AuthenticatedRoutes : UnauthenticatedRoutes}
        </Routes>
      </ModalProvider>
    </BrowserRouter>
  );
};

root.render(
  <React.StrictMode>
    <SessionProvider>
      <AppRouter />
    </SessionProvider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log);
