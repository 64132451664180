import { Input } from "@material-tailwind/react";
import { WidgetProps } from "@rjsf/utils";
import { t } from "../../../translate";
import CartoonInput from "../cartoon/Input";
import CartoonRangeInput from "../cartoon/Range";

const TextWidget = ({
  id,
  value,
  required,
  disabled,
  readonly,
  label,
  onChange,
  schema,
}: WidgetProps) => {

  const canBeASlider = schema.type === "number" && schema.minimum !== undefined && schema.maximum !== undefined;

  if (canBeASlider) {
    return (<div>
      <p className="cartoon text-2xl text-white text-center">{t(label)}</p>
      <CartoonRangeInput
        value={value || ""}
        color="deep-purple"
        onChange={(e) => onChange(e.target.value)}
        disabled={disabled || readonly}
        required={required}
        min={schema.minimum}
        max={schema.maximum}
      />
    </div>)
  }

  return (<div>
    <p className="cartoon text-2xl text-white text-center">{t(label)}</p>
    <CartoonInput
      id={id}
      placeholder={t(label)}
      type={schema.type === "number" ? "number" : "text"}
      value={value || ""}
      color="deep-purple"
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled || readonly}
      required={required}
    />
  </div>)
};

export default TextWidget;