import { Emitter, upgradeConfig } from "@pixi/particle-emitter";
import { Texture } from "@pixi/core";
import { Component } from "../../../common/Component/Component";

const EMITTER_CONFIG = (pos: { x: number; y: number; }) => ({

    "alpha": {
        "start": 0.5,
        "end": 0.5
    },
    "scale": {
        "start": 1,
        "end": 1,
        "minimumScaleMultiplier": 1
    },
    "color": {
        "start": "#ffffff",
        "end": "#ffffff"
    },
    "speed": {
        "start": 6000,
        "end": 6000,
        "minimumSpeedMultiplier": 1
    },
    "acceleration": {
        "x": 0,
        "y": 0
    },
    "maxSpeed": 0,
    "startRotation": {
        "min": 90,
        "max": 90
    },
    "noRotation": false,
    "rotationSpeed": {
        "min": 0,
        "max": 0
    },
    "lifetime": {
        "min": 0.20,
        "max": 0.20
    },
    "blendMode": "normal",
    "frequency": 0.02,
    "emitterLifetime": -1,
    "maxParticles": 100,
    "pos": {
        "x": 0,
        "y": 0
    },
    "addAtBack": false,
    "spawnType": "rect",
    "spawnRect": {
        "x": 0,
        "y": 0,
        "w": 1280,
        "h": 20
    }
});


export class RainComponent extends Component {
    private _emitter: Emitter;

    constructor(pos: { x: number; y: number }) {
        super()

        const upgradedConfig = upgradeConfig(EMITTER_CONFIG(pos), [Texture.from('/assets/particles/rain.png')]);

        this._emitter = new Emitter(this._container, upgradedConfig);
        this._emitter.emit = true;
    }

    get emitter() {
        return this._emitter;
    }
}