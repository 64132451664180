import { Application } from "@pixi/app";
import { WRAP_MODES } from "@pixi/core";
import { Container } from "@pixi/display";
import { DisplacementFilter } from "@pixi/filter-displacement";
import { Emitter } from "@pixi/particle-emitter";
import { Sprite } from "@pixi/sprite";
import { GameClass } from "../../engine/GameClass";
import DestroySmokeEmitter from "./entities/DestroySmokeEmitter";
import FlameEmitter from "./entities/FlameEmitter";
import PotionSmokeEmitter from "./entities/PotionSmokeEmitter";
import { WordInGame, WordSoupGameState } from "./state";
import WordEntity from "../common/entities/WordEntity";
import { GameRule } from "./component/GameRule";

export interface SoupGameConfiguration {
    /**
     * The game instance id to request backend.
     * @type {string[]}
     * @memberof SoupGameConfiguration
     */
    gameInstanceId: string;

    /**
     * The words to use in the game.
     * @type {string[]}
     * @memberof SoupGameConfiguration
     */
    words: string[];

    /**
     * The acceleration of words [0 -> 100]
     * @type {number}
     * @memberof SoupGameConfiguration
     */
    acceleration: number;

    /**
     * The interval in milliseconds to spawn new words.
     * @type {number}
     * @memberof SoupGameConfiguration
     */
    spawnInterval: number;

    /**
     * Whether to disable particles.
     * @type {boolean}
     * @memberof SoupGameConfiguration
     */
    disableParticles: boolean;

    /**
     * Action to take when a word is missed.
     * @type {("retry" | "destroy" | "none")}
     * @memberof SoupGameConfiguration
     */
    onMiss: "retry" | "destroy" | "none";

    /**
     * Number of player lives.
     * @type { number }
     * @memberof SoupGameConfiguration
     */
    lives: number

    /**
     * Time of the game.
     * @type { { minute: number, second: number }}
     * @memberof SoupGameConfiguration
     */
    time: { minute: number, second: number }

    /**
     * Time of the game.
     * @type { {  displayLives: boolean; displayCountdown: boolean; displayScore: boolean; }}
     * @memberof SoupGameConfiguration
     */
    interface: { 
        displayLives: boolean;
        displayCountdown: boolean;
        displayScore: boolean;
    }
}

/**
 * Class representing the SoupGame.
 * @extends GameClass
 */
class SoupGame extends GameClass {
    private state: WordSoupGameState = {
        words: [],
        currentTime: 0,
        lastSpawnTime: 0,
    }
    private emitters: Emitter[] = [];
    private animationLayer: Container = new Container();
    private currentWord: WordInGame | null = null;
    private shadows: Sprite = Sprite.from('/assets/soup/shadows.png');
    private displacementMap = Sprite.from('/assets/soup/soup_displacement.jpg');
    
    private rules!: GameRule;

    /**
     * Creates an instance of SoupGame.
     * @param {SoupGameConfiguration} config - The game configuration.
     * @param {Application} engine - The PIXI application instance.
     * @param {HTMLDivElement} container - The HTML container element.
     */
    constructor(
        private config: SoupGameConfiguration,
        engine: Application,
        container: HTMLDivElement
    ) {
        super(engine);

        this.loadBackgroundImage();
        this.initEmitters();
        this.addEventListeners(container);
        this.rules = new GameRule(
            engine.screen,
            engine.stage,
            this.config
        )

    }

    /**
     * Loads the background image and configures the stage.
     * @private
     */
    private loadBackgroundImage() {
        const scene = Sprite.from('/assets/soup/scene.png');
        const front = Sprite.from('/assets/soup/front-cauldron.png');
        const content = Sprite.from('/assets/soup/content.png');

        this.configureContent(content);
        this.configureDisplacementMap();
        this.configureStage(scene, front, content);
    }

    /**
     * Configures the content sprite.
     * @private
     * @param {Sprite} content - The content sprite.
     */
    private configureContent(content: Sprite) {
        content.width = this.engine.screen.width;
        content.height = this.engine.screen.height;
        content.filters = [new DisplacementFilter(this.displacementMap, 40)];
    }

    /**
     * Configures the displacement map sprite.
     * @private
     */
    private configureDisplacementMap() {
        this.displacementMap.width = this.engine.screen.width * 3;
        this.displacementMap.height = this.engine.screen.width * 3;
        this.displacementMap.texture.baseTexture.wrapMode = WRAP_MODES.REPEAT;
    }

    /**
     * Configures the stage with provided sprites.
     * @private
     * @param {Sprite} scene - The scene sprite.
     * @param {Sprite} front - The front cauldron sprite.
     * @param {Sprite} content - The content sprite.
     */
    private configureStage(scene: Sprite, front: Sprite, content: Sprite) {
        scene.width = this.engine.screen.width;
        scene.height = this.engine.screen.height;

        this.shadows.width = this.engine.screen.width;
        this.shadows.height = this.engine.screen.height;

        front.width = this.engine.screen.width;
        front.height = this.engine.screen.height;

        this.engine.stage.addChild(this.displacementMap);
        this.engine.stage.addChild(scene);
        this.engine.stage.addChild(content);
        this.engine.stage.addChild(this.animationLayer);
        this.engine.stage.addChild(front);
        this.engine.stage.addChild(this.shadows);
    }

    /**
     * Initializes particle emitters.
     * @private
     */
    private initEmitters() {
        if (this.config.disableParticles) {
            return;
        }
        this.emitters.push(new PotionSmokeEmitter(
            this.animationLayer,
            this.engine.screen.width,
            this.engine.screen.height
        ).get());
        this.emitters.push(new FlameEmitter(this.animationLayer, { x: 246, y: 375 }).get());
        this.emitters.push(new FlameEmitter(this.animationLayer, { x: 276, y: 395 }).get());
    }

    /**
     * Adds event listeners to the container.
     * @private
     * @param {HTMLDivElement} container - The HTML container element.
     */
    private addEventListeners(container: HTMLDivElement) {
        container.addEventListener('keydown', this.handleKeyDown.bind(this));
    }

    /**
     * Handles keydown events.
     * @private
     * @param {KeyboardEvent} e - The keyboard event.
     */
    private handleKeyDown(e: KeyboardEvent) {
        if (this.currentWord === null) {
            this.tryStartNewWord(e.key.toUpperCase());
        } else {
            this.tryContinueCurrentWord(e.key.toUpperCase());
        }
    }

    /**
     * Attempts to start a new word with the given key.
     * @private
     * @param {string} key - The key pressed.
     */
    private tryStartNewWord(key: string) {
        const word = this.state.words.find(w => (w.destroyIn === undefined && w.capturedSince === undefined) && w.value.startsWith(key));

        if (word) {
            if (word.value === key) {
                this.captureWord(word);
            }
            word.entity.typed = key;
            this.currentWord = word;
        }
    }

    /**
     * Attempts to continue typing the current word with the given key.
     * @private
     * @param {string} key - The key pressed.
     */
    private tryContinueCurrentWord(key: string) {
        if (this.currentWord === null) {
            return;
        }

        const wordValue = this.currentWord.value;
        const typed = this.currentWord.entity.typed + key;

        if (wordValue.startsWith(typed)) {
            this.currentWord.entity.typed = typed;

            if (wordValue === typed) {
                this.captureWord(this.currentWord);
            }
        } else if (this.config.onMiss === 'retry') {
            this.currentWord.entity.typed = '';
            this.currentWord = null;
        } else if (this.config.onMiss === 'destroy') {
            this.currentWord.entity.missed = key;
            this.currentWord.destroyIn = 7;
            this.currentWord = null;
        }
    }

    /**
     * Captures the word, marking it as complete.
     * @private
     * @param {WordInGame} word - The word to capture.
     */
    private captureWord(word: WordInGame) {
        this.currentWord = null;
        word.capturedSince = 0;
        word.speed = 0;
    }

    /**
     * Returns a random word from the configuration.
     * @private
     * @returns {string} - A random word.
     */
    private getRandomWord(): string {
        const words = this.config.words;
        return words[Math.floor(Math.random() * words.length)];
    }

    /**
     * Spawns a new word entity.
     * @private
     */
    private spawnWord() {
        const value = this.getRandomWord().toUpperCase();
        const entity = new WordEntity(value, this.engine.screen.width);

        entity.x = (Math.random() * 0.25 + 0.4) * this.engine.screen.width;
        entity.y = -entity.text.height;
        this.state.words.push({
            value: value,
            entity,
            speed: 0
        });

        this.animationLayer.addChild(entity.get());
    }

    /**
     * Destroys a word entity and optionally creates particles.
     * @private
     * @param {WordInGame} word - The word to destroy.
     * @param {boolean} [particles=true] - Whether to create particles.
     */
    private destroyWord(word: WordInGame, color: string | null = null) {
        this.removeWordFromState(word);

        if (color) {
            this.createDestroyParticles(word, color);
        }
    }

    /**
     * Removes a word from the game state.
     * @private
     * @param {WordInGame} word - The word to remove.
     */
    private removeWordFromState(word: WordInGame) {
        this.animationLayer.removeChild(word.entity.get());
        this.state.words = this.state.words.filter(l => l !== word);

        if (this.currentWord === word) {
            this.currentWord = null;
        }
    }

    /**
     * Creates particles for destroying a word.
     * @private
     * @param {WordInGame} word - The word to create particles for.
     */
    private createDestroyParticles(word: WordInGame, color: string) {
        if (this.config.disableParticles) {
            return;
        }
        const pos = { x: word.entity.get().x, y: word.entity.get().y + word.entity.get().height / 2 };

        this.emitters.push(new DestroySmokeEmitter(
            this.animationLayer,
            word.entity.get().width,
            pos,
            color
        ).get());
    }


    /**
     * Updates the game state time.
     * @private
     * @param {number} delta - The time delta.
     */
    private updateGameState(delta: number) {
        this.state.currentTime = new Date().getTime();
    }

    /**
     * Updates the displacement map position.
     * @private
     * @param {number} delta - The time delta.
     */
    private updateDisplacementMap(delta: number) {
        this.displacementMap.y += delta;

        if (this.displacementMap.y > this.displacementMap.height) {
            this.displacementMap.y = 0;
        }
    }

    /**
     * Updates the particle emitters.
     * @private
     * @param {number} delta - The time delta.
     */
    private updateEmitters(delta: number) {
        if (this.config.disableParticles) {
            return;
        }
        this.shadows.alpha += Math.random() * 0.05 - 0.025;
        this.shadows.alpha = Math.min(1, Math.max(0.5, this.shadows.alpha));

        this.emitters = this.emitters.filter(emitter => {
            emitter.update(delta * 0.01);
            return emitter.emit || emitter.particleCount > 0;
        });
    }

    /**
     * Checks if a new word should be spawned.
     * @private
     */
    private maybeSpawnWord() {
        if (this.state.currentTime - this.state.lastSpawnTime > this.config.spawnInterval) {
            this.spawnWord();
            this.state.lastSpawnTime = this.state.currentTime;
        }
    }

    /**
     * Updates the positions and states of words.
     * @private
     * @param {number} delta - The time delta.
     */
    private updateWords(delta: number) {
        this.state.words.forEach(word => {
            if (word.capturedSince === undefined) {
                this.updateWordPosition(word, delta);
            } else {
                this.updateCapturedWord(word, delta);
            }

            if (word.destroyIn !== undefined) {
                word.destroyIn -= delta;
                if (word.destroyIn <= 0) {
                    this.rules.loseLife();
                    this.destroyWord(word, '#ff8080');
                }
            }

            if (word.entity.get().y > this.engine.screen.height * 0.75) {
                this.rules.loseLife();
                this.destroyWord(word, '#80ff80');
            }
        });
    }

    /**
     * Updates the position of an uncaptured word.
     * @private
     * @param {WordInGame} word - The word to update.
     * @param {number} delta - The time delta.
     */
    private updateWordPosition(word: WordInGame, delta: number) {
        word.speed += delta * this.config.acceleration / 5000;
        word.entity.get().y += word.speed * delta;
        word.entity.get().alpha = Math.max(0, Math.min(1, 1 - (word.entity.get().y - this.engine.screen.height * 0.72) / (this.engine.screen.height * 0.03)));
    }

    /**
     * Updates the state of a captured word.
     * @private
     * @param {WordInGame} word - The word to update.
     * @param {number} delta - The time delta.
     */
    private updateCapturedWord(word: WordInGame, delta: number) {
        if (word.capturedSince === undefined) {
            return;
        }
        word.capturedSince += delta;
        const entity = word.entity.get();
        entity.scale = { x: 1 + (word.capturedSince / 10), y: 1 + (word.capturedSince / 10) };
        entity.alpha = 1 - (word.capturedSince / 10);

        if (word.capturedSince > 10) {
            this.rules.wordTyped(word.value)
            this.destroyWord(word);
        }
    }

    // Public mandatory methods

    /**
     * Updates the game state.
     * @param {number} delta - The time delta.
     */
    public update(delta: number): void {
        this.updateGameState(delta);
        this.updateDisplacementMap(delta);
        this.updateEmitters(delta);
        this.maybeSpawnWord();
        this.updateWords(delta);
        this.rules.update();
    }

    /**
     * Destroys the game instance and cleans up resources.
     */
    public destroy(): void {
        // Add cleanup logic here if necessary
    }
}

export default SoupGame;
