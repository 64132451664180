import { ArrayFieldTemplateProps } from "@rjsf/utils";
import { useEffect, useRef, useState } from "react";
import { t } from "../../../translate";
import { FaPlus, FaTimes } from "react-icons/fa";
import ArrayChoiceTemplate from "./ArrayChoiceTemplate";

function ArrayFieldTemplate(props: ArrayFieldTemplateProps) {

    if ((props.schema.items as any)?.enum) {
        return <ArrayChoiceTemplate {...props} />;
    }
    return (
        <div>
            <p className="mb-2 cartoon text-2xl text-white">{t(props.title ?? "")}</p>
            <div className="flex flex-wrap gap-2">
                {props.items.map((element, index) => (
                    <ChipInput
                        key={index}
                        element={element}
                        placeholder={`...`}
                    />
                ))}

                {props.canAdd && (
                    <button
                        type="button"
                        className="bg-indigo-500 text-white font-bold py-2 px-3 rounded-full hover:bg-indigo-700"
                        onClick={props.onAddClick}
                    >
                        <FaPlus />
                    </button>
                )}
            </div>
        </div>
    );
}

const ChipInput = ({ element, placeholder }: { element: any; placeholder: string }) => {
    const value = element.children.props.value || element.children.props.formData || "";
    const [inputValue, setInputValue] = useState<string>(value);
    const inputRef = useRef<HTMLInputElement | null>(null);

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setInputValue(e.target.value);
        element.children.props.onChange(e.target.value);
    };

    useEffect(() => {
        if (inputRef.current) {
            const scrollWidth = inputRef.current.scrollWidth;
            inputRef.current.style.width = `${Math.max(scrollWidth, 50)}px`;
        }
    }, [inputValue]);

    useEffect(() => {
        setInputValue(value);
    }, [value]);


    return (
        <div className="flex items-center bg-indigo-100 text-indigo-700 px-3 py-2 rounded-full flex-wrap">
            <input
                ref={inputRef}
                type="text"
                value={inputValue}
                onChange={handleChange}
                className="cartoon bg-transparent outline-none text-lg placeholder:text-indigo-400"
                placeholder={placeholder}
                style={{ width: `${Math.max(inputValue.length * 8, 50)}px`, minWidth: "50px" }} // Dynamic width
            />
            <button
                type="button"
                className="ml-2 text-indigo-700 hover:text-red-700"
                onClick={element.onDropIndexClick(element.index)}
            >
                <FaTimes />
            </button>
        </div>
    );
};

export default ArrayFieldTemplate;