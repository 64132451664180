import { Container } from "@pixi/display";

export class Component {
    protected _container: Container

    constructor() {
        this._container = new Container();
    }

    public get container(): Container {
        return this._container;
    }
}