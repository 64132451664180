import { authenticatedClient, updateAuthHeader } from "../client";
import { CreatePatientRequest } from "./dto/create-patient.request";
import { PatientFoundResponse } from "./dto/patient-found.response";

export class PatientApi {

    static async createPatient(request: CreatePatientRequest) {
        updateAuthHeader();
        return await authenticatedClient.post<PatientFoundResponse>('/patient', request);
    }

    static async getPatients() {
        updateAuthHeader();
        return await authenticatedClient.get<PatientFoundResponse[]>('/patient');
    }

    static async getPatient(id: string) {
        updateAuthHeader();
        return await authenticatedClient.get<PatientFoundResponse>(`/patient/${id}`);
    }
}