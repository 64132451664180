import fr from './locales/fr/translation.json';

export const t = (key: string) => {
    if (!key) {
        return '';
    }

    if (fr.hasOwnProperty(key)) {
        return fr[key as keyof typeof fr];
    }

    return key;
}