import { EnemyEntity } from "../entities/EnemyEntity";
import { EntityState } from "../entities/state/EntityState";
import { PlayerEntity } from "../entities/PlayerEntity";
import { SpaceGameEntity } from "../entities/SpaceGameEntity";

export class MoveEnemiesSystem {
    constructor(private readonly gameRule: SpaceGameEntity) {}
    
    public handle(
        delta: number,
        enemy: EnemyEntity,
        player: PlayerEntity
    ) {
        const distanceX = player.x - enemy.x;
        const distanceY = player.y - enemy.y;

        this.computeNextAngle(enemy, distanceX, distanceY)
        this.computeNextPos(delta, enemy, distanceX, distanceY)
        this.checkCollision(enemy, player)
    }

    private computeNextAngle(
        enemy: EnemyEntity,
        distanceX: number,
        distanceY: number
    ) {
        enemy.angle = Math.atan2(distanceX, distanceY) * (180 / Math.PI) * -1

        if (enemy.angle < 0) {
            enemy.angle += 360;
        }
    }

    private computeNextPos(
        delta: number,
        enemy: EnemyEntity,
        distanceX: number,
        distanceY: number
    ) {
        const bulletPosition = enemy.container.position;
        const distance = Math.sqrt(distanceX * distanceX + distanceY * distanceY);

        if (distance === 0) {
            return;
        }

        enemy.container.position.set(
            bulletPosition.x + (distanceX / distance) * enemy.speed * delta,
            bulletPosition.y + (distanceY / distance) * enemy.speed * delta
        )
    }

    private checkCollision(enemy: EnemyEntity, player: PlayerEntity) {
        const bulletPosition = enemy.container.position;
        const targetPosition = player.container.position;

        const minX = targetPosition.x - player.width;
        const maxX = targetPosition.x + player.width;

        const minY = targetPosition.y - player.height;
        const maxY = targetPosition.y + player.height;

        const checkX = bulletPosition.x >= minX && bulletPosition.x <= maxX;
        const checkY = bulletPosition.y >= minY && bulletPosition.y <= maxY;


        if (checkX && checkY && enemy.state === EntityState.ALIVE) {
            enemy.state = EntityState.DEATH_BEGIN;
            this.gameRule.hitPlayer();
        }
    }
}