import { Container } from "@pixi/display";
import { TextStyle } from "@pixi/text";
import { GameEntity } from "../../../engine/entities";
import { TextComponent } from "../../space/game/component/TextComponent";


class WordEntity implements GameEntity {
    private _container: Container = new Container();
    private _text: TextComponent;
    private _typedText: TextComponent;
    private _typed: string = '';
    private _missed: string = '';
    private _lock: string = '';

    constructor(private value: string, screenWidth: number) {
        this._text = new TextComponent(this.value, new TextStyle({
            fill: 0xffffff,
            fontSize: screenWidth * 0.05,
            fontFamily: 'Titan One'
        }));
        this._typedText = new TextComponent('', new TextStyle({
            fill: 0x4dff4d,
            fontSize: screenWidth * 0.05,
            fontFamily: 'Titan One'
        }));

        this._container.addChild(this._text, this._typedText);
        this.positionText();
    }

    private positionText() {
        this._container.pivot.x = this._text.width / 2;
        this._container.pivot.y = this._text.height / 2;
    }

    set typed(typed: string) {
        this._typed = typed;
        this._typedText.text = typed;
    }

    set x(x: number) {
        this._container.x = x;
    }

    set y(y: number) {
        this._container.y = y;
    }

    get typed() {
        return this._typed;
    }

    set missed(missed: string) {
        this._missed = missed;
        const valueWithMissedChar = this.value.substring(0, this.typed.length) + missed + this.value.substring(this.typed.length + 1);
        this._text.tint = 0xff8080;
        this._text.text = valueWithMissedChar;
    }

    get missed() {
        return this._missed;
    }

    get text(): TextComponent {
        return this._text;
    }

    update() {
        this._text.update(this.positionText.bind((this)));
        this._typedText.update(this.positionText.bind((this)));
    }

    get() {
        return this._container;
    }

    tryLockHit(letter: string): boolean {
        const tempTyped = this._lock + letter;
        const isMatch = this._text.text.startsWith(tempTyped);

        if (isMatch) {
            this._lock = tempTyped;
        }
        return isMatch;
    }
}


export default WordEntity;