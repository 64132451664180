import { Emitter, upgradeConfig } from "@pixi/particle-emitter";
import { Texture } from "@pixi/core";
import { Component } from "../../../common/Component/Component";

const EMITTER_CONFIG = (pos: { x: number; y: number; }) => ({
    "alpha": {
        "start": 0.8,
        "end": 0.1
    },
    "scale": {
        "start": 1,
        "end": 0.3,
        "minimumScaleMultiplier": 1
    },
    "color": {
        "start": "#ff0000",
        "end": "#ff0000"
    },
    "speed": {
        "start": 2000,
        "end": 2000,
        "minimumSpeedMultiplier": 1
    },
    "acceleration": {
        "x": 0,
        "y": 0
    },
    "maxSpeed": 0,
    "startRotation": {
        "min": 0,
        "max": 0
    },
    "noRotation": false,
    "rotationSpeed": {
        "min": 0,
        "max": 0
    },
    "lifetime": {
        "min": 0.7,
        "max": 0.7
    },
    "blendMode": "normal",
    "frequency": 0.7,
    "emitterLifetime": 0.8,
    "maxParticles": 1000,
    "pos": {
        "x": pos.x,
        "y": pos.y
    },
    "addAtBack": false,
    "spawnType": "burst",
    "particlesPerWave": 8,
    "particleSpacing": 45,
    "angleStart": 0

});


export class ExplosionComponent extends Component {
    private _emitter: Emitter;

    constructor(pos: { x: number; y: number }) {
        super()

        const upgradedConfig = upgradeConfig(EMITTER_CONFIG(pos), [Texture.from('/assets/particles/particle.png')]);

        this._container.width = 600
        this._container.height = 600
        this._emitter = new Emitter(this._container, upgradedConfig);
    }

    get emitter() {
        return this._emitter;
    }
}