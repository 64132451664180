import React from 'react';

interface CartoonInputProps extends React.SelectHTMLAttributes<HTMLSelectElement> {}

const CartoonSelect: React.FC<CartoonInputProps> = (props) => {
  return (
    <div className='flex items-center p-1 bg-gradient-to-l from-indigo-200 to-indigo-300 rounded-2xl shadow-lg'>
      <select
        {...props}
        style={{ WebkitAppearance: 'none', MozAppearance: 'textfield' }}
        className='cartoon text-2xl text-white w-full bg-indigo-900 text-center bg-opacity-50 outline-none rounded-xl p-3 placeholder:text-white placeholder:opacity-50 shadow-inner'
      >
        {props.children}
      </select>
    </div>
  );
};

export default CartoonSelect;
