import { FieldTemplateProps } from "@rjsf/utils";

const FieldTemplate = (props: FieldTemplateProps) => {
    const { children } = props;
    return (
      <div className="mb-1 px-2">
        {children}
      </div>
    );
  };

export default FieldTemplate;