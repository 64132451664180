import { useParams } from "react-router-dom";
import GameRender from "../../engine/GameRender";
import SoupGame, { SoupGameConfiguration } from "./Game";
import { useEffect, useState } from "react";
import { getParameters } from "../common/server/SpaceGameParameter";

const SoupGameRender: React.FC = () => {
    const params = useParams<{ instanceId: string }>();
    const [config, setConfig] = useState<SoupGameConfiguration>(
        {
            gameInstanceId: '',
            words: [],
            acceleration: 0,
            spawnInterval: 0,
            disableParticles: false,
            onMiss: "none",
            lives: 0,
            time: { minute: 0, second: 0 },
            interface: { 
                displayLives: true,
                displayCountdown: true,
                displayScore: true,
            }
        }
    )
    const [isGameLunched, setIsGameLunched] = useState(false);

    useEffect(() => {
        if (!params.instanceId) {
            return;
        }

        getParameters(params.instanceId)
            .then(
                (value) => {
                    setConfig({
                        gameInstanceId: params.instanceId ?? '',
                        words: value.settings.words,
                        acceleration: value.settings.acceleration,
                        spawnInterval: value.settings.spawnInterval,
                        disableParticles: value.settings.disableParticles,
                        onMiss: value.settings.onMiss,
                        lives: value.settings.lives,
                        time: value.settings.time,
                        interface: value.settings.interface
                    })
                    setIsGameLunched(true);
                }
            );

    }, [params.instanceId]);

    return (
        <div>
            {
                isGameLunched &&
                <GameRender
                    width={1280}
                    height={720}
                    gameClass={(engine, container) => new SoupGame(config, engine, container)}
                />
            }
        </div>
    );
}

export default SoupGameRender;
