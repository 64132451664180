import { Text, TextStyle } from "@pixi/text";
import { Component } from "./Component";

export interface CountDownData {
    minutes?: number;
    seconds?: number;
    miliseconds?: number;
}

export class CountDownComponent extends Component {
    private _isRunning: boolean;
    private _milisecondCountDown: number;
    private _text: Text;
    private _startTimestamp: number
    private _elapsedTime: number;

    constructor(
        value: CountDownData,
        textStyle: TextStyle,
    ) {
        super()

        this._isRunning = false;
        this._milisecondCountDown = this.convertCountDownDataToMilisecond(value);
        this._text = new Text(
            this.getFormatedText(),
            textStyle
        );
        this._container.addChild(this._text);
        this._startTimestamp = 0;
        this._elapsedTime = 0;
    }

    private convertCountDownDataToMilisecond(value: CountDownData): number {
        let result = 0;

        if (value.minutes) {
            result += value.minutes * 60 * 1000;
        }
        if (value.seconds) {
            result += value.seconds * 1000;
        }
        return result;
    }

    private getFormatedText(): string {
        const timiRemaining = this._milisecondCountDown - this._elapsedTime;
        const elapsedSeconds = Math.floor(timiRemaining / 1000);
        let milliseconds = timiRemaining % 1000;
        let seconds = elapsedSeconds % 60;
        let minutes = Math.floor(elapsedSeconds / 60);

        if (minutes < 0) {
            minutes = 0;
        }
        if (seconds < 0) {
            seconds = 0;
        }
        if (milliseconds < 0) {
            milliseconds = 0;
        }
        return `${minutes.toString().padStart(2, '0')}` + 
        `:${seconds.toString().padStart(2, '0')}`;
    }

    public start() {
        this._isRunning = true;
        this._startTimestamp = Date.now();
    }


    public update() {
        if (this._isRunning) {
            this._elapsedTime = Date.now() - this._startTimestamp

            if (this._elapsedTime >= this._milisecondCountDown) {
                this._isRunning = false;
            } 
            this._text.text = this.getFormatedText();
        }
    }

    public get isRunning(): boolean {
        return this._isRunning;
    }
}