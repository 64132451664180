import { ReactNode, useEffect, useState } from "react";
import { buildStyles, CircularProgressbar } from "react-circular-progressbar";
import { FaAdjust, FaAnchor, FaGamepad, FaMouse, FaStar } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PatientFoundResponse } from "../../api/patient/dto/patient-found.response";
import { PatientApi } from "../../api/patient/patient.api";
import { GameCard } from "../../components/game/GameCard";
import { Game, GAMES } from "../../constants/games";
import { Progress } from "@material-tailwind/react";


const AchievementCard: React.FC<{ icon: ReactNode, title: string, description: string }> = ({ icon, title, description }) => {
  return (
    <div className="bg-white p-5 rounded-xl flex gap-5 items-center">
      <div className="text-deep-purple-400 text-3xl">
        {icon}
      </div>
      <div className="flex flex-col">
        <h3 className="text-lg font-bold">{title}</h3>
        <p className="text-sm">{description}</p>
      </div>
    </div>
  )
}

const ProgressionCard: React.FC<{ title: string, value: number }> = ({ title, value }) => {
  return (
    <div className="flex gap-5">
      <div className="flex flex-col w-32">
        <p className="text-lg">{title}</p>
        <div className="flex items-center">
          <FaStar className={`text-deep-purple-400 ${value >= 30 ? "text-deep-purple-400" : "text-gray-400"} text-sm`} />
          <FaStar className={`text-deep-purple-400 ${value >= 60 ? "text-deep-purple-400" : "text-gray-400"} text-sm`} />
          <FaStar className={`text-deep-purple-400 ${value >= 90 ? "text-deep-purple-400" : "text-gray-400"} text-sm`} />
        </div>
      </div>
      <div className="flex items-center flex-1">
        <Progress
          color="deep-purple"
          value={value}
          size="lg"
        />
      </div>
    </div>
  )
}

const PatientProgession: React.FC<{ patient: PatientFoundResponse }> = ({ patient }) => {
  return (
    <div className="p-10 rounded-lg bg-gray-50 flex gap-20 items-center justify-between">
      <div className="relative min-w-60">
        <img
          src="/assets/illustrations/avatar/1.png"
          alt={`${patient?.firstname} ${patient?.lastname}`}
          className="h-60 w-60"
        />
        <CircularProgressbar
          value={20}
          text="24"
          className="w-24 h-24 absolute bottom-0 right-0 transform translate-x-1/4 translate-y-1/4 font-bold"
          strokeWidth={12}
          background={true}
          styles={buildStyles({
            pathColor: "#7e57c2",
            textColor: "#7e57c2",
            trailColor: "#eeeeee",
            textSize: "30px",
            backgroundColor: '#fff',
            strokeLinecap: 'butt',
          })}
        />
      </div>
      <div className="flex flex-1 flex-col gap-3 max-w-full overflow-hidden">
        <h2 className="text-2xl font-bold">
          Derniers succès
        </h2>
        <div className="overflow-x-auto small-scrollbar">
          <div className="flex gap-5 w-max">
            <AchievementCard icon={<FaMouse />} title="Premier jeu" description="Jouer à un jeu pour la première fois" />
            <AchievementCard icon={<FaAdjust />} title="Polyvalent" description="Jouer à 5 jeux différents" />
            <AchievementCard icon={<FaAnchor />} title="As du bateau" description="Gagner 5 parties de bataille navale" />
            <AchievementCard icon={<FaAnchor />} title="As du bateau" description="Gagner 5 parties de bataille navale" />
          </div>
        </div>
        <div className="bg-white rounded-lg p-5">
          <h2 className="text-2xl font-bold mb-3">
            Progression
          </h2>
          <div className="flex flex-col gap-3">
            <ProgressionCard title="Coordination" value={65} />
            <ProgressionCard title="Rapidité" value={45} />
            <ProgressionCard title="Agilité" value={90} />
          </div>
        </div>
      </div>
    </div>
  )
}

const PatientGames = () => {
  const [patient, setPatient] = useState<PatientFoundResponse | null>(null);
  const { patientId } = useParams<{ patientId: string }>();
  const navigate = useNavigate();

  const getCurrentPatient = () => {
    if (!patientId) return;
    PatientApi.getPatient(patientId)
      .then((response) => {
        setPatient(response.data);
      })
      .catch((error) => {
        toast.error("Impossible de récupérer ce joueur");
        navigate("/patient");
      });
  };

  useEffect(() => {
    getCurrentPatient();
  }, []);

  return (
    <div className="flex flex-col flex-1 w-full">
      <div className="w-full p-5 bg-white rounded-3xl min-h-full">
        <h1 className="text-4xl mb-5 px-5 text-amber-700 font-bold">
          <FaGamepad className="inline align-middle" />
          <span className="ml-2 align-middle">
            Jeux
          </span>
        </h1>
        {patient && <PatientProgession patient={patient} />}
        <h2 className="text-2xl font-bold mt-5 pl-6">
          Jeux disponibles
        </h2>
        <div className="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-3 h-max w-full px-3 gap-4 m-2">
          {GAMES.map((game: Game) => (
            <GameCard playerId={patient?.playerId ?? ""} game={game} key={game.name} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PatientGames;
