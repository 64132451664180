import { JSONSchema7 } from 'json-schema';
import { GameSettingsResponse } from '../../api/game-settings/dto/game-settings.response';
import GameApi from '../../api/game/game-api';

export class GameSettingsHandler {
    sanitizeInput(settingsStructs: JSONSchema7) {
        settingsStructs.$schema = undefined;
        return settingsStructs;
    }

    setDefaultValues(settingsStructs: JSONSchema7, settingsValues: GameSettingsResponse) {
        if (!settingsStructs.properties) {
            return;
        }

        settingsStructs.$schema = undefined;
        for (const key in settingsValues.settings) {
            const settings = settingsStructs.properties[key];
            const value = settingsValues.settings[key];
                
            if (key === 'type' || !settings || settings === true || value === undefined) {
                continue;
            }
            settings.default = value;

            if (settings.type === 'array') {
                settings.additionalItems = {
                    type: 'string'
                }
            }
            if (key === 'type') {
                settings.readOnly = true
            }
        }
    }
}