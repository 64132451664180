import { BackgroundEntity } from "../entities/BackgroundEntity";

export class ParallaxSystem {
    constructor(
        private backgrounds: BackgroundEntity[],
        private screen: { height: number }
    ) {}

    handle(delta: number) {
        for (const background of this.backgrounds) {
            background.y += (5 * delta)

            if (background.y >= this.screen.height) {
                background.y = -background.height;
            }
        }
    }
}