import { Application } from "@pixi/app";
import { EntityState } from "../entities/state/EntityState";
import { Entity } from "../../../common/entities/Entity";

export class DeathEntitySystem {
    constructor(private readonly engine: Application) {}

    public handle(
        index: number,
        entity: Entity,
        entitiesFromList?: Entity[]
    ) {
        if (entity.state === EntityState.DEATH_END) {
            this.engine.stage.removeChild(entity.container);
            entitiesFromList?.splice(index, 1);
        }
    }
}