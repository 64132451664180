import { useEffect, useState } from "react";
import { GameSettingsStructureResponse } from "../../api/game-settings/dto/game-settings-structure.response";
import { GameSettingsResponse } from "../../api/game-settings/dto/game-settings.response";
import GameSettingsApi from "../../api/game-settings/game-settings.api";
import { LunchGameResponse } from "../../api/game/dto/lunch-game.response";
import GameApi from "../../api/game/game-api";
import GameSettingsForm from "../game-settings/GameSettingsForm";
import PresetButtons from "../game-settings/PresetButtons";
import { error } from "console";

interface SettingsProps {
  gameType: string;
  playerId: string;
  onValidate: (data: LunchGameResponse) => void;
}

const GameSettings = ({ gameType, playerId, onValidate }: SettingsProps) => {
  const [formValues, setFormValues] = useState<
    GameSettingsResponse | undefined
  >(undefined);
  const [formStruct, setFormStruct] = useState<
    GameSettingsStructureResponse | undefined
  >(undefined);

  const updateOption = (option: string) => {
    GameSettingsApi.getPatientSettings({
      playerId: playerId,
      gameType: option,
    })
      .then((response) => {
        setFormValues(response.data);
      })
      .catch((error) => {
        console.log(error);
      });
    GameSettingsApi.getSettingsTypeStructure(option)
      .then((response) => setFormStruct(response.data))
      .catch((error) => {
        console.log(error);
      });
  };
  const onSubmit = (value: { [key: string]: any }) => {
    GameApi.lunch({
      playerId: playerId,
      settings: {
        ...value,
        type: value.type,
      },
    }).then((response) => {
      const game = response.data;

      onValidate(game);
    })
  };

  useEffect(() => {
    updateOption(gameType);
  }, [gameType]);

  return (
    <div className="w-full h-full">
      {formValues && formStruct && (
        <GameSettingsForm
          settingsValue={formValues.settings}
          settingsStructure={formStruct.settings}
          onUpdate={(settings) => setFormValues({
            ...formValues,
            settings: {...settings}
          })}
          onSubmit={onSubmit}
        />
      )}
      {/* {formStruct?.preset && formValues?.settings && (
        <PresetButtons
          presets={formStruct.preset}
          settings={formValues}
          onUpdate={(settings) => setFormValues(settings)}
        />
      )} */}
    </div>
  );
};

export default GameSettings;
