import { EnemyEntity } from "./EnemyEntity";
import { Graphics } from "@pixi/graphics";
import { Container, DisplayObject } from "@pixi/display";
import { Entity } from "../../../common/entities/Entity";

export class BulletEntity extends Entity {
    private _target: EnemyEntity;
    private _speed: number;
    private _letter: string;
    private _square: Graphics;


    constructor(
        position: { x: number, y: number },
        target: EnemyEntity,
        speed: number,
        letter: string
    ) {
        super();

        this._container.position = position;
        this._target = target;
        this._speed = speed;
        this._letter = letter;
        this._square = new Graphics();
        
        this._square.beginFill(0x33FF39);
        this._square.drawRect(0, 0, 5, 10);
    }

    public get target(): EnemyEntity {
        return this._target;
    }

    public get speed(): number {
        return this._speed;
    }

    public hit() {
        this._target.hit(this._letter)
    }

    public display(container: Container<DisplayObject>): void {
        this._container.addChild(this._square);
        container.addChild(this._container);
    }

    public update(_delta: number): void {
        
    }
}