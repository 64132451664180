import { Text } from "@pixi/text";

export class TextComponent extends Text {
    private _isLoaded = false;

    update(callback?: () => void) {
        const beforeWidth = this.width;
        const beforeHeight = this.height;

        if (!this._isLoaded) {
            this.updateText(false);
        }

        if (beforeWidth !== this.width || beforeHeight !== this.height) {
            this._isLoaded = true;
            

            if (callback !== undefined) {
                callback()
            }
        }
    }
}