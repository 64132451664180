import { ArrayFieldTemplateProps } from "@rjsf/utils";
import { useEffect, useState } from "react";
import { t } from "../../../translate";

function ArrayChoiceTemplate(props: ArrayFieldTemplateProps) {
    const choices = (props.schema.items as any)?.enum ?? [];
    const realOrder = props.items.map((element) => element.children.props.formData || element.children.props.value || "");
    const [order, setOrder] = useState<string[]>(realOrder);
    const [currentDate, setCurrentDate] = useState(new Date());

    const toggleElement = (label: string) => () => {
        if (order.includes(label)) {
            setOrder(order.filter((el) => el !== label));
        } else {
            setOrder([...order, label]);
        }
    };

    useEffect(() => {
        if (realOrder.join() === order.join())
            return;
        if (order.length > realOrder.length) {
            return props.onAddClick();
        }
        if (order.length < realOrder.length) {
            setCurrentDate(new Date());
            return props.items[realOrder.length - 1].onDropIndexClick(realOrder.length - 1)
        }

        for (const idx in order) {
            const el = order[idx];
            const element = props.items[idx];

            element.children.props.onChange(el);
        }
    }, [realOrder, order]);

    return (
        <div>
            <p className="mb-2 cartoon text-2xl text-white">{t(props.title ?? "")}</p>
            <div className="flex flex-wrap gap-2">
                {choices.map((label: string) => (
                    <div
                        style={{ cursor: "pointer" }}
                        onClick={toggleElement(label)}
                        key={label}
                        className={`flex items-center
                        ${order.includes(label) ? "bg-indigo-500 text-white" : "bg-indigo-100 text-indigo-700"}
                         px-3 py-1 rounded-full flex-wrap`}
                    >
                        <span className="cartoon text-lg">{t(label)}
                            <span className="ml-2">
                                {order.includes(label) ? `(${order.indexOf(label) + 1})` : ""}
                            </span>
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
}


export default ArrayChoiceTemplate;