import { Sprite } from "@pixi/sprite";
import { SpriteCache } from "../init/SpriteCache";
import { Text, TextStyle } from "@pixi/text";
import { Component } from "../../../common/Component/Component";

export class LivesComponents extends Component {
    private _lives: Sprite[];
    private _isInfinite: boolean;
    private _screen: { width: number };

    constructor(
        lives: number,
        screen: { width: number },
        isInfinite: boolean = false,
    ) {
        super()

        this._lives = [];
        this._screen = screen;
        this._isInfinite = isInfinite;

        this.displayLives(lives);
    }

    private displayInfinite() {
        const text = new Text('∞', new TextStyle({
            fill: 0xffffff,
            fontSize: this._screen.width * 0.050,
            fontFamily: 'Titan One',
        }))


        text.position.y = text.position.y - this._screen.width * 0.015;
        this._container.addChild(text);
    }

    private displayLives(lives: number) {
        if (lives > 5) {
            throw new Error('Lives must be under or equal 5');
        }

        if (this._isInfinite) {
            this.displayInfinite();
            return;
        }
        for (let i = 0; i < lives; i++) {
            const sprite = SpriteCache.hearth;

            sprite.position.x = 40 * i + sprite.position.x;
            sprite.scale.x = 0.25;
            sprite.scale.y = 0.25;

            this._lives.push(sprite);
            this._container.addChild(sprite);
        }
    }

    get lives(): number {
        return this._lives.length;
    }

    get isInfinite(): boolean {
        return this._isInfinite;
    }

    public loseLife() {
        const life = this._lives.pop();

        if (!life || this._isInfinite) {
            return;
        }

        this._container.removeChild(life)
    }
}