import React from 'react';

interface CartoonRangeInputProps extends React.InputHTMLAttributes<HTMLInputElement> {}

const CartoonRangeInput: React.FC<CartoonRangeInputProps> = (props) => {
  return (
    <div className="cartoon-range flex flex-col items-center mt-3">
      <input
        {...props}
        type="range"
        className="w-full h-6 bg-gradient-to-r from-indigo-400 to-indigo-500 rounded-full appearance-none outline-none border-4 border-indigo-200 shadow-lg cursor-pointer"
        style={{
          accentColor: "transparent",
        }}
      />
      <div className="w-full flex justify-between text-white text-xs font-bold cartoon">
        <span className="transform rotate-6 text-white">{props.min}</span>
        <span className="transform text-lg text-white">{props.value}</span>
        <span className="transform -rotate-6 text-white">{props.max}</span>
      </div>
    </div>
  );
};

export default CartoonRangeInput;
