import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import GameRender from "../../engine/GameRender";
import { SpaceGame, SpaceGameConfiguration } from "./game/SpaceGame";
import { getParameters } from "../common/server/SpaceGameParameter";

const SpaceGameRender: React.FC = () => {
    const params = useParams<{ instanceId: string }>();
    const [config, setConfig] = useState<SpaceGameConfiguration>(
        {
            gameInstanceId: '',
            words: [],
            enemySpeed: 0,
            enemyNumber: 0,
            enemySpacing: 0,
            playerLives: 0,
            infiniteLives: false
        }
    )
    const [isGameLunched, setIsGameLunched] = useState(false);


    useEffect(() => {
        if (!params.instanceId) {
            return;
        }

        getParameters(params.instanceId)
            .then(
                (value) => {
                    setConfig({
                        gameInstanceId: params.instanceId ?? '',
                        words: value.settings.words,
                        enemySpeed: value.settings.enemySpeed,
                        enemyNumber: value.settings.enemyNumber,
                        enemySpacing: value.settings.enemySpacing,
                        playerLives: value.settings.playerLives,
                        infiniteLives: value.settings.infiniteLives
                    })
                    setIsGameLunched(true);
                }
            );

    }, [params.instanceId]);

    return (
        <div>
            {
                isGameLunched &&
                <GameRender
                    width={1280}
                    height={720}
                    gameClass={(engine, container) => new SpaceGame(
                        config,
                        engine,
                        container
                    )}
                />
            }
        </div>
    );
}

export default SpaceGameRender;