import { Button, Input } from "@material-tailwind/react";
import { useEffect, useState } from "react";
import { FaPlus, FaSearch, FaUsers } from "react-icons/fa";
import { PatientFoundResponse } from "../../api/patient/dto/patient-found.response";
import { PatientApi } from "../../api/patient/patient.api";
import CreatePatientForm from "../../components/patient/CreatePatient";
import {
  PatientCard
} from "../../components/patient/PatientCard";
import { useModal } from "../../providers/ModalProvider";

const Patient = () => {
  const [patients, setPatients] = useState<PatientFoundResponse[]>([]);
  const { openModal, closeModal } = useModal();
  const [search, setSearch] = useState("");

  const getCurrentPatient = () => {
    PatientApi.getPatients().then((response) => {
      setPatients(response.data);
    });
  };

  useEffect(() => {
    getCurrentPatient();
  }, []);

  const handlePatientCreated = () => {
    closeModal();
    getCurrentPatient();
  };

  const handleCreatePatient = () => {
    openModal(<CreatePatientForm onUpdate={handlePatientCreated} />);
  };

  return (
    <div className="flex flex-col space-y-4">
      <div className="flex flex-col gap-10 items-center h-max w-full bg-white p-10 rounded-3xl min-h-full">
        <div className="w-full">
          <div className="w-full flex justify-between items-center">
            <h1 className="text-3xl mb-7 text-deep-purple-800 font-bold">
              <FaUsers className="inline align-middle" /> <span className="align-middle">Patients</span>
            </h1>
            <div className="max-w-[50em]">
              <Input
                crossOrigin=""
                variant="static"
                icon={<FaSearch />}
                type="text"
                placeholder="Rechercher un patient"
                color="deep-purple"
                onChange={(e) => setSearch(e.target.value)}
                value={search}
              />
            </div>
          </div>
          <div className="w-full">
            <p className="text-xl">
              Choisissez l'enfant avec lequel vous travaillez aujourd'hui
            </p>
            <p className="text-gray-500">
              Retrouvez les profils et choisissez le patient que vous souhaitez suivre.
            </p>
          </div>
          <div className="flex justify-end w-full">
            <Button
              onClick={handleCreatePatient}
              color="deep-purple"
              className="mt-3"
              size="sm"
            ><FaPlus className="inline" /> Créer un patient</Button>
          </div>
        </div>
        <div className="flex items-center flex-wrap justify-center h-max w-full max-w-[70em] px-3 gap-4">
          {patients.filter((patient) => `${patient.firstname} ${patient.lastname}`.toLowerCase().includes(search.toLowerCase())).map((patient, i) => (
            <PatientCard key={patient.id} index={i} patient={patient} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Patient;
