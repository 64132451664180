import { Sprite } from "@pixi/sprite";
import { Container, DisplayObject } from "@pixi/display";
import { Entity } from "../../../common/entities/Entity";

export class BackgroundEntity extends Entity {
    private backgroundSprite: Sprite;

    constructor(
        screen: { height: number, width: number },
        sprite: Sprite,
        position?: { x: number, y: number }
    ) {
        super()


        this.backgroundSprite = sprite;


        this.backgroundSprite.width = screen.width;
        this.backgroundSprite.height = screen.width;

        if (position) {
            this.container.position.set(position.x, position.y);
        }
    }

    public display(container: Container<DisplayObject>): void {
        this.container.addChild(this.backgroundSprite);
        container.addChild(this._container);
    }

    public update(delta: number): void {
        // throw new Error("Method not implemented.");
    }
}