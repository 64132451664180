import { SubmitButtonProps } from "@rjsf/utils";
import CartoonButton from "../cartoon/Button";

const SubmitButtonTemplate = (props: SubmitButtonProps) => {
  return (
    <div className="mt-10">
      <CartoonButton value="Jouer" />
    </div>
  );
};

export default SubmitButtonTemplate;