const NotFound: React.FC = () => {
  return (
    <div className="flex flex-col items-center justify-center flex-1 space-y-4 p-2">
      <img
        className="w-36 h-36"
        src="/assets/millo/millo-sad.png"
        alt="Millo"
      />
      <div className="w-full max-w-[40em] relative">
        <h1 className="text-3xl text-center mb-7">404 - Page non trouvée</h1>
        <p className="text-center">La page que vous cherchez n'existe pas.</p>
      </div>
    </div>
  );
};

export default NotFound;
