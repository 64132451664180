import { Application } from "@pixi/app";
import { GameClass } from "../../../engine/GameClass";
import { SpriteCache } from "./init/SpriteCache";
import { PlayerFactory } from "./init/PlayerFactory";
import { PlayerEntity } from "./entities/PlayerEntity";
import { EnemyEntity } from "./entities/EnemyEntity";
import { MoveEnemiesSystem } from "./system/MoveEnemiesSystem";
import { EnemyHitSystem } from "./system/EnemyHitSystem";
import { DeathEntitySystem } from "./system/DeathEntitySystem";
import { PlayerShootSystem } from "./system/PlayerShootSystem";
import { BulletEntity } from "./entities/BulletEntity";
import { BulletSystem } from "./system/BulletSystem";
import { AssetManager } from "./init/AssetManager";
import { RainComponent } from "./component/RainComponent";
import { SpaceGameEntity } from "./entities/SpaceGameEntity";
import { SpaceGameState } from "./entities/state/SpaceGameState";
import { ParallaxSystem } from "./system/ParallaxSystem";
import { BackgroundEntity } from "./entities/BackgroundEntity";
import { SpawnEnemySystem } from "./system/SpawnEnemySystem";

export interface SpaceGameConfiguration {
    gameInstanceId: string,
    words: string[],
    enemySpeed: number,
    enemyNumber: number,
    enemySpacing: number,
    playerLives: number,
    infiniteLives: boolean;
}

export class SpaceGame extends GameClass {
    private spawnEnemySystem!: SpawnEnemySystem;
    private enemyHitSystem!: EnemyHitSystem;
    private deathEntitySystem!: DeathEntitySystem;
    private playerShootSystem!: PlayerShootSystem;
    private bulletSystem!: BulletSystem;
    private moveEnemySystem!: MoveEnemiesSystem;
    private parallaxSystem!: ParallaxSystem

    private backgrounds!: BackgroundEntity[];
    private player!: PlayerEntity;
    private enemies!: EnemyEntity[];
    private bullets!: BulletEntity[];
    private gameRules!: SpaceGameEntity;
    private rainComponent!: RainComponent;

    private _isLoaded!: boolean;

    private _screen: { width: number; height: number }

    constructor(
        private readonly config: SpaceGameConfiguration,
        engine: Application,
        container: HTMLDivElement
    ) {
        super(engine)

        this._screen = {
            width: this.engine.screen.width,
            height: this.engine.screen.height
        }

        this.loadGame();
        this.loadEventListeners(container);
    }

    private loadAssets(): Promise<void> {
        return AssetManager.loadFonts()
    }

    private loadBackground() {
        this.backgrounds = [
            new BackgroundEntity(
                this._screen,
                SpriteCache.background,
                { x: 0, y: -this._screen.height }
            ),
            new BackgroundEntity(
                this._screen,
                SpriteCache.background
            )
        ];


        this.rainComponent = new RainComponent({ x: 200, y: 200 })
    }

    private loadPlayer() {
        const playerFactory = new PlayerFactory(this._screen);

        this.player = playerFactory.generate({ health: 100 })
    }

    private loadEnemies() {
        this.enemies = []
    }

    private loadGameRules() {
        this.gameRules = new SpaceGameEntity(
            {
                width: this._screen.width,
                height: this._screen.height
            },
            this.config
        )

        this.gameRules.container.zIndex = 1;
    }

    private loadEventListeners(container: HTMLDivElement) {
        container.addEventListener('keydown', (ev: KeyboardEvent) => {
            switch (this.gameRules.gameState) {
                case SpaceGameState.STARTED:
                    this.playerShootSystem.handle(
                        ev.key,
                        this.enemies
                    )
                    break;
                case SpaceGameState.GAME_END:
                    if (ev.key === 'Enter') {
                        this.engine.stage.removeChildren()
                        this.loadGame()
                    };
                    break;

                default:
                    break;
            }
        });
    }

    private loadSystems() {
        this.spawnEnemySystem = new SpawnEnemySystem(
            this.engine,
            this.config
        );
        this.enemyHitSystem = new EnemyHitSystem(this.engine);
        this.deathEntitySystem = new DeathEntitySystem(this.engine);
        this.playerShootSystem = new PlayerShootSystem(this.player);
        this.moveEnemySystem = new MoveEnemiesSystem(this.gameRules);
        this.bulletSystem = new BulletSystem(this.gameRules);
        this.parallaxSystem = new ParallaxSystem(
            this.backgrounds,
            this._screen
        )
    }

    private loadGame() {
        this._isLoaded = false;
        this.engine.stage.sortableChildren = true;
        this.bullets = []


        this.loadAssets().then(() => {
            this._isLoaded = true;
            this.loadBackground();
            this.loadPlayer();
            this.loadEnemies();
            this.loadGameRules()
            this.loadSystems();
        })
    }

    private displayElements() {
        this.backgrounds.forEach((background) => background.display(this.engine.stage));
        this.gameRules.display(this.engine.stage);
        this.player.display(this.engine.stage);
        this.engine.stage.addChild(this.rainComponent.container);
    }

    updateEnemies(delta: number) {
        for (let index = 0; index < this.enemies.length; index++) {
            this.moveEnemySystem.handle(delta, this.enemies[index], this.player);
            this.enemyHitSystem.handle(this.player, this.enemies[index]);
            this.deathEntitySystem.handle(index, this.enemies[index], this.enemies);
            this.enemies[index]?.update(delta);
        }
        this.spawnEnemySystem.handle(this.enemies);
    }

    updateBullets(delta: number) {
        const nextBullet = this.playerShootSystem.pullNextTarget();

        if (nextBullet !== undefined) {
            this.bullets.push(nextBullet)
            this.playerShootSystem.aim(nextBullet);
            nextBullet.display(this.engine.stage);
        }
        for (let index = 0; index < this.bullets.length; index++) {
            this.bulletSystem.handle(delta, this.bullets[index]);
            this.deathEntitySystem.handle(index, this.bullets[index], this.bullets);
            this.enemies[index]?.update(delta);
        }
    }

    update(delta: number): void {
        if (!this._isLoaded) {
            return;
        }

        if (this.gameRules.gameState === SpaceGameState.NOT_STARTED) {
            this.displayElements();
        }

        this.gameRules.update(delta);

        if (this.gameRules.gameState !== SpaceGameState.STARTED) {
            return;
        }
        this.updateEnemies(delta);
        this.updateBullets(delta);
        this.player.update(delta);
        this.parallaxSystem.handle(delta);
        this.rainComponent.emitter.update(delta * 0.01);

        if (!this.rainComponent.emitter.emit) {
            this.rainComponent.emitter.emit = true;
        }
    }


    destroy(): void {
        // throw new Error("Method not implemented.");
    }

}