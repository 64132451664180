import { Container } from "@pixi/display";
import { Emitter, EmitterConfigV3 } from "@pixi/particle-emitter";
import { GameEmitter } from "../../../engine/entities";

const EMITTER_CONFIG = (width: number, height: number): EmitterConfigV3 => ({
    lifetime: {
        min: 1,
        max: 1
    },
    frequency: 1 / 40,
    maxParticles: 200,
    pos: {
        x: width * 0.35,
        y: height * 0.70
    },
    behaviors: [
        {
            type: "alpha",
            config: {
                alpha: {
                    list: [
                        {
                            time: 0,
                            value: 0
                        },
                        {
                            time: 0.1,
                            value: 0.15
                        },
                        {
                            time: 1,
                            value: 0
                        }
                    ]
                }
            }
        },
        {
            type: "scale",
            config: {
                scale: {
                    list: [
                        {
                            time: 0,
                            value: 0
                        },
                        {
                            time: 0.1,
                            value: 2
                        },
                        {
                            time: 1,
                            value: 3
                        }
                    ]
                },
                minMult: 1
            }
        },
        {
            type: "color",
            config: {
                color: {
                    list: [
                        {
                            time: 0,
                            value: "#80ff80"
                        },
                        {
                            time: 1,
                            value: "#d4ffd4"
                        }
                    ]
                }
            }
        },
        {
            type: "rotationStatic",
            config: {
                min: 0,
                max: 360
            }
        },
        {
            type: "blendMode",
            config: {
                blendMode: "screen"
            }
        },
        {
            type: "textureSingle",
            config: {
                texture: "/assets/particles/smoke.png"
            }
        },
        {
            type: "spawnShape",
            config: {
                type: "rect",
                data: {
                    x: 0,
                    y: 0,
                    w: width * 0.3,
                    h: 0
                }
            }
        },
        {
            type: "moveAcceleration",
            config: {
                 accel: {
                      x: 0,
                      y: -700
                 },
                 minStart: 200,
                 maxStart: 200,
                 rotate: true
            }
        }
    ]
});

class PotionSmokeEmitter implements GameEmitter {
    private emitter: Emitter;

    constructor(container: Container, width: number, height: number) {
        this.emitter = new Emitter(container, EMITTER_CONFIG(width, height));
        this.emitter.emit = true;
    }

    get() {
        return this.emitter;
    }

}


export default PotionSmokeEmitter;