import { useEffect, useState } from "react";
import { FaCertificate, FaCheckCircle, FaClock, FaHome } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { PatientFoundResponse } from "../../api/patient/dto/patient-found.response";
import { PatientApi } from "../../api/patient/patient.api";


const PatientPage = () => {
  const [patient, setPatient] = useState<PatientFoundResponse | null>(null);
  const { patientId } = useParams<{ patientId: string }>();
  const navigate = useNavigate();

  const getCurrentPatient = () => {
    if (!patientId) return;
    PatientApi.getPatient(patientId)
      .then((response) => {
        setPatient(response.data);
      })
      .catch((error) => {
        toast.error("Impossible de récupérer ce joueur");
        navigate("/patient");
      });
  };

  useEffect(() => {
    getCurrentPatient();
  }, []);

  return (
    <div className="flex flex-col flex-1 w-full">
      <div className="w-full p-5 bg-white rounded-3xl min-h-full">
        <h1 className="text-4xl mb-5 px-5 text-amber-700 font-bold">
          <FaHome className="inline align-middle" />
          <span className="ml-2 align-middle">
            Mon profil
          </span>
        </h1>
        <div className="p-10 rounded-lg bg-gray-50 flex gap-20 items-center justify-between">
          <div className="relative">
            <img
              src="/assets/illustrations/avatar/1.png"
              alt={`${patient?.firstname} ${patient?.lastname}`}
              className="h-60 w-60"
            />
          </div>
          <div className="flex flex-1 flex-col gap-3">
            <h1 className="text-3xl mb-2 font-bold text-amber-700">
              {patient?.firstname} {patient?.lastname}
            </h1>
            <p className="text-2xl">
              <b>Suivi depuis:</b> {new Date().toLocaleDateString('fr-FR')}
            </p>
            <p>
              Note:
            </p>
          </div>
        </div>
          <div className="flex gap-10 mt-5">
            <div className="bg-amber-300 text-white p-8 rounded-lg flex gap-5 items-center flex-1">
              <FaClock className="text-5xl" />
              <div>
                <p className="text-2xl">7H</p>
                <p>Passées sur la plateforme</p>
              </div>
            </div>
            <div className="bg-amber-500 text-white p-8 rounded-lg flex gap-5 items-center flex-1">
              <FaCheckCircle className="text-5xl" />
              <div>
                <p className="text-2xl">52</p>
                <p>Niveaux débloqués</p>
              </div>
            </div>
            <div className="bg-amber-700 text-white p-8 rounded-lg flex gap-5 items-center flex-1">
              <FaCertificate className="text-5xl" />
              <div>
                <p className="text-2xl">5</p>
                <p>Diplômes obtenus</p>
              </div>
            </div>
          </div>
      </div>
    </div>
  );
};

export default PatientPage;
