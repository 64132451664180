import { useEffect, useState } from "react";
import { LunchGameResponse } from "../../api/game/dto/lunch-game.response";
import GameApi from "../../api/game/game-api";
import GameSettingsForm from "../game-settings/GameSettingsForm";
import { GameLevelSettingsApi } from "../../api/game-level-settings/game-level-settings-api";
import { GameLevelSettingsStructureResponse } from "../../api/game-level-settings/dto/game-level-settings-structure.response";
import { GameLevelSettingsResponse } from "../../api/game-level-settings/dto/game-level-settings.response";

interface GameSettingsProps {
    gameType: string;
    playerId: string;
    onValidate: (data: LunchGameResponse) => void;
}

const GameLevelSettings = ({ gameType, playerId, onValidate }: GameSettingsProps) => {
    const [formValues, setFormValues] = useState<
        GameLevelSettingsResponse | undefined
    >(undefined);
    const [formStruct, setFormStruct] = useState<
        GameLevelSettingsStructureResponse | undefined
    >(undefined);
    const [comingSoon, setComingSoon] = useState<boolean>(false)

    const updateOption = (option: string) => {
        GameLevelSettingsApi.getPatientSettings({
            playerId: playerId,
            type: option,
        })
            .then((response) => {
                setFormValues(response.data);
            })
            .catch((error) => {
                setComingSoon(true);
            });

        GameLevelSettingsApi.getSettingsTypeStructure(option)
            .then((response) => setFormStruct(response.data))
            .catch((error) => {
                console.log(error);
            });
    };
    const onSubmit = (value: { [key: string]: any }) => {
        GameApi.lunch({
            playerId: playerId,
            level: {
                ...value,
                type: value.type,
            },
        }).then((response) => {
            const game = response.data;

            onValidate(game);
        })
    };

    useEffect(() => {
        updateOption(gameType);
    }, [gameType]);

    return (
        <div className="w-full h-full">
            {!comingSoon && formValues && formStruct && (
                <GameSettingsForm
                    settingsValue={formValues}
                    settingsStructure={formStruct.settings}
                    onUpdate={(settings) => setFormValues(settings)}
                    onSubmit={onSubmit}
                />
            )}
            {comingSoon && (
                <div className="flex items-center justify-center h-full">
                    <h1 className="cartoon text-5xl font-bold mb-10 text-white">Bientôt disponible !</h1>
                </div>
            )}
        </div>
    );
};

export default GameLevelSettings;
