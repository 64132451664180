import React from "react";
import { FaRocket, FaBullseye, FaClock, FaMouse } from "react-icons/fa";

export type Game = {
    enum?: string;
    name: string;
    image?: string;
    subgames?: Game[];
    summary?: string;
    description?: React.ReactNode;
    icon?: React.ReactNode;
    gameType?: string;
}

export const GAMES: Game[] = [
    // {
    //     enum: "BoatGame",
    //     name: "Navigateur Averti",
    //     summary: "Un défi de rapidité et de coordination sur les mers tumultueuses.",
    //     description: (
    //         <>
    //             Prends le contrôle de ton bateau et affronte les vagues. 
    //             Montre tes compétences en évitant les obstacles et en collectant des trésors !
    //             <br /><br />
    //             Sauras-tu devenir le navigateur le plus rapide ?
    //         </>
    //     ),
    //     icon: (<FaRocket />),
    //     gameType: "Synchronisation et Coordination",
    //     image: "/assets/illustrations/covers/boat.png"
    // },
    // {
    //     enum: "PlatformerGame",
    //     name: "Plateforme Aventure",
    //     summary: "Un parcours rempli de défis où chaque saut compte.",
    //     description: (
    //         <>
    //             Prépare-toi à sauter, courir et escalader dans ce monde vibrant.
    //             Affronte des ennemis et surmonte des pièges pour atteindre la fin de chaque niveau.
    //             <br /><br />
    //             Seras-tu capable de battre tous les records ?
    //         </>
    //     ),
    //     icon: (<FaRocket />),
    //     gameType: "Raccourcis clavier",
    //     image: "/assets/illustrations/covers/platformer.png"
    // },
    // {
    //     enum: "SpaceGame",
    //     name: "Protecteur de l'Espace",
    //     summary: "Un jeu de tir où rapidité et précision sont essentielles.",
    //     description: (
    //         <>
    //             Bienvenue à bord de ton vaisseau spatial !
    //             <br /><br />
    //             Ta mission : protéger l'espace en détruisant les météorites qui arrivent. 
    //             Pour les détruire, <b>tape correctement le mot qui apparaît sur chaque météorite.</b>
    //             Plus tu tapes vite et juste, plus tu repousses les météorites et protèges ton vaisseau.
    //             <br /><br />
    //             À toi de jouer, capitaine !
    //         </>
    //     ),
    //     icon: (<FaRocket />),
    //     gameType: "Dactylographie",
    //     image: "/assets/illustrations/covers/space.png"
    // },
    {
        enum: "DragAndDropGame",
        name: "Labyrinthe explosif",
        summary: "Un jeu où la précision est la clé.",
        description: (
            <>
                Teste ton esprit logique avec ce jeu de glisser-déposer.
                Organise les éléments pour compléter le tableau et débloquer des niveaux supplémentaires.
                <br /><br />
                Utilise ta créativité pour résoudre chaque défi !
            </>
        ),
        icon: (<FaMouse />),
        gameType: "Précision",
        image: "/assets/illustrations/covers/drag_and_drop.png"
    },
    {
        name: "Chasseur de Précision",
        summary: "Un jeu de tir avec différents modes de défi.",
        image: "/assets/illustrations/covers/hunter.png",
        subgames: [
            {
                enum: "HunterGameTargetMode",
                name: "Nombre de Cibles",
                summary: "Tire sur des cibles avec précision.",
                description: (
                    <>
                        Dans ce mode, mets à l'épreuve ta précision en tirant sur des cibles.
                        <br /><br />
                        Atteins le score parfait pour devenir le maître des cibles !
                    </>
                ),
                icon: (<FaBullseye />),
                gameType: "Tir",
                image: "/assets/illustrations/covers/hunter_target.png"
            },
            {
                enum: "HunterGameTimeMode",
                name: "Mode Chronométré",
                summary: "Tire sur les cibles le plus vite possible.",
                description: (
                    <>
                        Montre ta rapidité dans ce défi chronométré.
                        <br /><br />
                        Chaque seconde compte, alors fais preuve de rapidité et de précision !
                    </>
                ),
                icon: (<FaClock />),
                gameType: "Tir",
                image: "/assets/illustrations/covers/hunter_time.png"
            },
            {
                enum: "HunterGameTimeTargetMode",
                name: "Nombre et Chronomètre",
                summary: "Un mélange de rapidité et de précision.",
                description: (
                    <>
                        Combine tes compétences de tir et ta rapidité dans ce mode.
                        <br /><br />
                        Tâche de toucher le bon nombre de cibles avant la fin du temps imparti !
                    </>
                ),
                icon: (<FaBullseye />),
                gameType: "Tir",
                image: "/assets/illustrations/covers/hunter_target_time.png"
            }
        ],
    },
    {
        enum: "SoupGame",
        name: "La soupe des mots",
        summary: "Un jeu où la rapidité rencontre la précision",
        description: (
            <>
                Bienvenue dans le jeu de la soupe !
                <br /><br />
                Dans ce jeu tu dois taper des mots le plus vite possible.
            </>
        ),
        icon: (<FaMouse />),
        gameType: "Précision",
        image: "/assets/illustrations/covers/soup.png"
    },
];
