import axios, { AxiosError } from "axios";
import { clearTokens, getRefreshToken, getToken, updateTokens } from "../providers/SessionProvider";

export const axiosClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        "Content-Type": "application/json",
    }
});

export const authenticatedClient = axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
        "Content-Type": "application/json",
    }
});

const refreshToken = async () => {
    const token = getRefreshToken();
    try {
        const response = await axiosClient.post<{
            refresh_token: string;
            access_token: string;
        }>("/auth/refresh", { refreshToken: token });
        updateTokens(response.data.access_token, response.data.refresh_token);
    } catch (error) {
        console.error("Error refreshing token", error);
        clearTokens();
        window.location.href = "/sign-in";
    }
}

authenticatedClient.interceptors.response.use(
    (response) => response,
    async (error: AxiosError) => {
        const config = error.config;
        const isRetry = (config as any)?._retry;

        if (error.response?.status !== 401 || !config || isRetry) {
            return Promise.reject(error);
        }

        await refreshToken();
        updateAuthHeader();

        config.headers.Authorization = `Bearer ${getToken()}`;
        (config as any)._retry = true;

        return axios(config);
    }
);

export const updateAuthHeader = () => {
    const token = getToken();
    authenticatedClient.defaults.headers.Authorization = `Bearer ${token}`;
}