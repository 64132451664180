export class AngleHelper {
    public static computeAngle(
        firstPos: { x: number, y: number },
        secondPos: { x: number, y: number }
    ): number {
        const distanceX = firstPos.x - secondPos.x;
        const distanceY = firstPos.y - secondPos.y

        let result = Math.atan2(distanceX, distanceY) * (180 / Math.PI) * -1

        if (result < 0) {
            result += 360;
        }
        return result;
    }
}