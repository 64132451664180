import { Container } from "@pixi/display";
import { Emitter, EmitterConfigV3 } from "@pixi/particle-emitter";
import { GameEmitter } from "../../../engine/entities";

const EMITTER_CONFIG = (pos: { x: number; y: number; }): EmitterConfigV3 => ({
    lifetime: {
        min: 0.25,
        max: 0.5
    },
    frequency: 0.01,
    maxParticles: 50,
    pos,
    behaviors: [
        {
            type: "alpha",
            config: {
                alpha: {
                    list: [
                        {
                            time: 0,
                            value: 0.62
                        },
                        {
                            time: 1,
                            value: 0
                        }
                    ]
                }
            }
        },
        {
            type: "moveSpeedStatic",
            config: {
                min: 20,
                max: 20
            }
        },
        {
            type: "scale",
            config: {
                scale: {
                    list: [
                        {
                            time: 0,
                            value: 0.1
                        },
                        {
                            time: 1,
                            value: 0.4
                        }
                    ]
                },
                minMult: 1
            }
        },
        {
            type: "color",
            config: {
                color: {
                    list: [
                        {
                            time: 0,
                            value: "fff191"
                        },
                        {
                            time: 1,
                            value: "ff622c"
                        }
                    ]
                }
            }
        },
        {
            type: "rotationStatic",
            config: {
                min: 265,
                max: 285
            }
        },
        {
            type: "textureRandom",
            config: {
                textures: [
                    "/assets/particles/flame.png"
                ]
            }
        },
        {
            type: "spawnShape",
            config: {
                type: "torus",
                data: {
                    x: 0,
                    y: 0,
                    radius: 2,
                    innerRadius: 0,
                    affectRotation: false
                }
            }
        }
    ]
});

class FlameEmitter implements GameEmitter {
    private emitter: Emitter;

    constructor(container: Container, pos: { x: number; y: number }) {
        this.emitter = new Emitter(container, EMITTER_CONFIG(pos));
        this.emitter.emit = true;
    }

    get() {
        return this.emitter;
    }

}


export default FlameEmitter;