import { t } from "../../translate";
import CartoonButton from "../game-settings/cartoon/Button";

interface GameEndProps {
    message: string;
    onClick: () => void
}

const GameEnd = ({ message, onClick }: GameEndProps) => {
    return (
        <div className="flex flex-col items-center justify-center h-full space-y-4">
            <p className="cartoon text-5xl text-white">{t(message)}</p>
            <CartoonButton onClick={onClick} value="Retour aux paramètres" />
        </div>
    )
}

export default GameEnd;