import { Emitter, upgradeConfig } from "@pixi/particle-emitter";
import { Texture } from "@pixi/core";
import { Component } from "../../../common/Component/Component";

const EMITTER_CONFIG = (pos: { x: number; y: number; }) => ({
    "alpha": {
        "start": 0.62,
        "end": 0
    },
    "scale": {
        "start": 0.25,
        "end": 0.75,
        "minimumScaleMultiplier": 1
    },
    "color": {
        "start": "#fff191",
        "end": "#ff622c"
    },
    "speed": {
        "start": 2000,
        "end": 2000,
        "minimumSpeedMultiplier": 1
    },
    "acceleration": {
        "x": 0,
        "y": 0
    },
    "maxSpeed": 0,
    "startRotation": {
        "min": 90,
        "max": 90
    },
    "noRotation": false,
    "rotationSpeed": {
        "min": 50,
        "max": 50
    },
    "lifetime": {
        "min": 0.05,
        "max": 0.05
    },
    "blendMode": "normal",
    "frequency": 0.0005,
    "emitterLifetime": -1,
    "maxParticles": 100,
    "pos": {
        "x": pos.x,
        "y": pos.y
    },
    "addAtBack": false,
    "spawnType": "circle",
    "spawnCircle": {
        "x": 0,
        "y": 0,
        "r": 10
    }

});


export class FlameComponent extends Component {
    private _emitter: Emitter;

    constructor(pos: { x: number; y: number }) {
        super()

        const upgradedConfig = upgradeConfig(
            EMITTER_CONFIG(pos), [
                Texture.from('/assets/particles/particle.png'),
                Texture.from('/assets/particles/flame.png'),
            ]);
        this._emitter = new Emitter(this._container, upgradedConfig);
    }

    get emitter() {
        return this._emitter;
    }
}