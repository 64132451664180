import { EnemyEntity } from "../entities/EnemyEntity";
import { PlayerEntity } from "../entities/PlayerEntity";
import { EntityState } from "../entities/state/EntityState";
import { Application } from "@pixi/app";

export class EnemyHitSystem {
    constructor(private readonly engine: Application) { }

    handle(player: PlayerEntity, enemy: EnemyEntity) {
        if (enemy.y > this.engine.screen.height) {
            enemy.state = EntityState.DEATH_END;
        }
    }
}