import { WidgetProps } from "@rjsf/utils";
import { t } from "../../../translate";
import CartoonSelect from "../cartoon/Select";

const SelectWidget = ({
  id,
  value,
  required,
  disabled,
  readonly,
  label,
  onChange,
  schema,
}: WidgetProps) => {

  return (<div>
    <p className="cartoon text-2xl text-white text-center">{t(label)}</p>
    <CartoonSelect
      id={id}
      value={value || ""}
      color="deep-purple"
      onChange={(e) => onChange(e.target.value)}
      disabled={disabled || readonly}
      required={required}
    >
      {(schema.enum as string[]).map((v) => {
        return <option key={v} value={v}>{t(v)}</option>
      })}
    </CartoonSelect>
  </div>)
};

export default SelectWidget;